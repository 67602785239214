import React, { useEffect, useState } from 'react';
import boygirl from '../assets/boygirl.jpg';

import academic from '../assets/icon/Academic.png';
import Clinical from '../assets/icon/Clinical.png';
import Consulting from '../assets/icon/consultant.png';
import Healthcare from '../assets/icon/healthcare.png';
import Heart from '../assets/icon/electrocardiogram.png';
import Research from '../assets/icon/research.png';
import Language from '../assets/icon/Language.png';

import CLINICAL from '../assets/icon/CLINICAL CONSULTATION.png';
import INTERVENTIONAL from '../assets/icon/INTERVENTIONAL CARDIOLOGY.png';
import CLINICALR from '../assets/icon/CLINICAL RESEARCH.png';
import MEDICAL from '../assets/icon/MEDICAL ACADEMIC CONSULTATION.png';

import member2 from '../assets/member21.png';
import member3 from '../assets/member21.png';
import image1 from '../assets/img1.jpeg';
import image2 from '../assets/img2.png';
import image3 from '../assets/img3.jpeg';

import certificate1 from '../assets/certificate1.jpg';
import certificate7 from '../assets/certificate2.jpg';
import certificate3 from '../assets/certificate3.jpg';
import certificate2 from '../assets/certificate4.jpg';
import certificate8 from '../assets/certificate8.png';
import certificate4 from '../assets/certificate6.jpg';
import certificate5 from '../assets/certificate7.jpg';
import certificate6 from '../assets/certificate8.jpg';
import certificate9 from '../assets/certificate9.jpg';
import certificate10 from '../assets/new certificate.jpeg';

import slider1 from '../assets/1) CLINICAL CONSULTATION.jpg';
import slider2 from '../assets/INTERVENTIONAL CARDIOLOGY.png';
import slider3 from '../assets/COPY OF Consulting.jpg';
import slider4 from '../assets/MEDICAL ACADEMIC.jpg';

import services1 from '../assets/services1.jpg';
import services2 from '../assets/services2.jpg';
import services3 from '../assets/services3.jpg';
import services4 from '../assets/services4.jpg';

import blog1 from '../assets/blog1.jpg';
import blog2 from '../assets/blog2.jpg';
import blog3 from '../assets/blog3.jpg';

import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import slogan from '../assets/slogan.jpg';
import { Link } from 'react-router-dom';

import { ContactForm } from './contact';
import { VideoPlaySection } from './video';

import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import VideoComponent from './videotest';
import { API_BASE_URL, API_ENDPOINTS, getblogelement } from '../../Services/constant';
import axios from 'axios';


// Initialize Swiper core with required modules
SwiperCore.use([Autoplay, Pagination]);
const Home = () => {
  return (
    <>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4 "></button>

        </div>
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="10000000">
            <img src={slider2} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-block d-md-block container">
              <i className='text-30  '>CLINICAL SKILLS</i>
              <h1>Specialized Clinical Physician Skilled in</h1>
              <p><li>	Cardiovascular Diseases & Hypertension</li>
                <li>Internal Medicine Diseases</li>
                <li>General Diseases </li>
              </p>
              <Link to={"/contact"}>  <button className='btn-two'>KNOW MORE</button></Link>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="5000">
            <img src={slider1} className="d-block w-100" alt="..." />
            <div className="carousel-caption  d-md-block container">
              <i className='  '>INTERVENTIONAL CARDIOLOGY SKILLS</i>

              <h1>Clinical physician with Cath-Lab surgical experience in</h1>
              <p>
                <li>Coronary Angiography (CAG)  </li>
                <li>	Percutaneous Coronary Intervention (PCI) </li>
                <li>Coronary Total Occultation (CTO) </li>
                <li>	Pacemaker Implantation </li>
                <li>		Valvular Heart disease & Ballon Valvuloplasty (PVMV)  </li>
                <li>	Renal Hypertension including Angiography & Stenting </li>
                <li>Peripherical Vascular Disease  </li>

              </p>
              <Link to={"/contact"}>  <button className='btn-two'>KNOW MORE</button></Link>

            </div>
          </div>
          <div className="carousel-item " data-bs-interval="4000">
            <img src={slider3} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-md-block container">
              <i>CLINICAL & HEALTHCARE CONSULTATION</i>
              <h1>An expert provides Unweaving Support in Clinical & Health care through</h1>
              <p>
                <li>Personalized Care Plans Consultation</li>
                <li>Cutting-edge Treatment Consultation</li>
              </p>
              <Link to={"/contact"}>  <button className='btn-two'>KNOW MORE</button></Link>

            </div>
          </div>
          <div className="carousel-item " data-bs-interval="4000">
            <img src={slider4} className="d-block w-100" alt="..." />
            <div className="carousel-caption  d-md-block container">
              <i >CLINICAL & NON-CLINICAL RESEARCH CONSULTATION</i>
              <h1>An expert adds value by contributing with</h1>
              <p>
                <li>	Clinical & non-clinical Research Consultation</li>
                <li>Medical Writing Consultation</li>
                <li>Clinical Innovation Consultation</li>
                <li>Academic Medical Science consultation through the "Easy Clinical Learning" strategy</li>
              </p>
              <Link to={"/contact"}>  <button className='btn-two'>KNOW MORE</button></Link>

            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <div>
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          </div>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* our-vision */}

      <OurVision />
      <VideoPlaySection />

      <OurServices />

      {/* make an appoinment  */}
      <div className='make-appointment'>
        <img src={slogan} alt="" />
        <div className="text-container " >
          <div data-aos="flip-left">
            <h1>Your Path to Health Begins Here</h1>
            <p>Take the first step toward improved health. We are prepared to offer you excellent care and individualized therapy.                  <br />                           <strong style={{ fontWeight: 5000 }}>Schedule your appointment right away.</strong>
            </p>
            <Link to='contact'>
              <button className="btn-two mt-4">
                MAKE AN APPOINTMENT
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className='choosing-us '>
        <div className='container'>
          <div className='text-center' data-aos="fade-right">
            <i>My Qualification</i>
            <h3>DR. SIAM AL MOBARAK SKILLS</h3>
          </div>
          <div>
            <h4><strong>Professional Skills:</strong>   </h4>
          </div>

          <div className="row py-4">
            <div className="col-md-4">
              <div className="choosing-us-box">

                <h5>Academic </h5>
                <div className='d-flex align-items-center'>
                  <img src={academic} style={{ width: "49px", marginRight: "1rem" }} alt="academic" />

                  <p>A skilled medical science instructor with an "Easy Clinical Learning" strategy for complex medical topics to the clinical learners.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Clinical </h5>
                <div className='d-flex align-items-center'>
                  <img src={Clinical} style={{ width: "49px", marginRight: "1rem" }} alt="Clinical" />

                  <p>Specialized Clinical Physician majoring in Cardiovascular Systems with Cath-Lab surgical experience.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Consulting </h5>
                <div className='d-flex align-items-center'>
                  <img src={Consulting} style={{ width: "49px", marginRight: "1rem" }} alt="Consulting" />

                  <p>An expert in Clinical & health care through "Cutting-edge treatments, personalized care plans and unwavering support." </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Healthcare </h5>
                <div className='d-flex align-items-center'>
                  <img src={Healthcare} style={{ width: "49px", marginRight: "1rem" }} alt="Healthcare" />

                  <p><br />Expertise as a Product Development Consultant for multinational healthcare organizations to ensure that, the organization's innovations meet the safety & performance and business requirements of "EUMDR, FDA, ISO, and NPMA" standards.
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Interventional Cardiology </h5>
                <div className='d-flex align-items-center'>
                  <img src={Heart} style={{ width: "49px", marginRight: "1rem" }} alt="Heart" />

                  <p>Clinical physician with Cath-Lab surgical experience in "Coronary Angiography (CAG), Percutaneous Coronary Intervention (PCI), Coronary Total Occultation (CTO), Pacemaker Implantation Surgeries, Ballon Valvuloplasty (PVMV) and assisted Renal Angiography & Stenting."  <br/></p>
                 
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Research</h5>
                <div className='d-flex align-items-center'>
                  <img src={Research} style={{ width: "49px", marginRight: "1rem" }} alt="Research" />

                  <p> <br /><br />Multiple clinical and non-clinical research publications have been published in collaboration with other teams. <br /><br /><br /> </p>
                </div>
              </div>
            </div>
            <div>
              <br /> <br />
              <h4> <strong> Language Skills: </strong>  </h4>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Bengali </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />

                  <p>Mother Tongue</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Chinese </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Working Proficiency</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>English </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Working Proficiency</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Hindi </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Communication Proficiency</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Italian </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Beginner's Proficiency</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Urdu  </h5>
                <div className='d-flex align-items-center'>
                <img src={Language} style={{width : "49px",marginRight :"1rem" }} alt="Language" />
                  <p>Communication Proficiency</p>
                </div>
              </div>
            </div>




          </div>
        </div>
      </div>

      <div className="Testimonials notech_clip">
        <div className="container">
          <div className="cmd">
            <div>
              <i>Achievements</i>
              <h3 className='mb-4'>Awards</h3>
            </div>
            {/* <button className='btn-one'>MORE STORIES</button> */}
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testimonial-box" data-aos="zoom-in">
                <div className="testimonial-img">
                  <img src={image1} alt="" />
                </div>
                <i></i>
                <p>
                  My biography in China was broadcast on Guangxi TV and Guangxi News, demonstrating not only my clinical but also surgical knowledge in the Cath-Lab.
                </p>
                <h5>	TV Interview</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testimonial-box" data-aos="zoom-in">
                <div className="testimonial-img1">
                  <img src={image2} alt="" />
                </div>
                <i></i>
                <p>
                  Awarded as "China Scholarship Council (CSC)" funded International Student scholarship for 3 years Master’s Degree in Cardiology.

                </p>
                <h5>	Awarded Scholarship</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testimonial-box" data-aos="zoom-in">
                <div className="testimonial-img">
                  <img src={image3} alt="" />
                </div>
                <i></i>
                <p>
                  Cardiology Case Report Competition Winner 2016.
                </p>
                <h5>Competition Winner</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OurBlog />
      <Consultation />
        <br /><br /><br /><br /> <br />
      <OurCertificate />
    </>
  )
}

export default Home;

export const OurVision = () => {
  return <>
    {/* our-vision */}
    < div className='our-vision ' >
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div data-aos="fade-left">
              <i >Some story behind </i>
              <h3>DR. SIAM AL MOBARAK </h3>
              <p style={{ textAlign: "left" }}>I am a cardiology clinical physician with Cath-Lab experience in Interventional Cardiology, have published research papers,  and am familiar with EUMDR, FDA, ISO, and NPMA regulations.</p>

              <div className='vision-mission '>
                <div className="row">
                  {/* <div className="col-md-6 ">
                    <h4>My Mission</h4>
                    <p>
                    My mission is simple yet profound: to provide exceptional clinical care and extensive research that not only saves lives but also enhances their quality. With a background deeply rooted in clinical research and consultancy with a dedication to continuous improvement, I aim to make a lasting impact on the health of our patients.

                    </p>
                  </div> */}
                  {/* <div className="col-md-6 " style={{ textAlign: "left" }}>
                    <h4>My Vision</h4>
                    <p>
                    My vision is to redefine clinical care through consultancy, research, innovation, expertise, and compassion.  I aspire to be a beacon of hope for those facing clinical care and healthcare challenges, offering cutting-edge treatments, personalized care plans, consultancy, and unwavering support.
                    </p>
                  </div> */}
                </div>
                <Link to={"/about"}>
                  <button className="btn-two mt-4">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className='vision-img' data-aos="fade-right">
              <img src={boygirl} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div >
  </>
}

export const OurCertificate = () => {

  const [slidesPerView, setSlidesPerView] = useState(4); // Default slides per view

  useEffect(() => {
    // Update slidesPerView based on screen width
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setSlidesPerView(1); // Set slides per view to 1 for mobile screens
      } else {
        setSlidesPerView(4); // Set default slides per view for larger screens
      }
    };

    // Initial setup
    handleResize();
    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>
    <div className='Achievement ' style={{paddingTop:"8rem"}}>
      <div className="container">
        <div data-aos="fade-right">
          <i>DR. SIAM AL MOBARAK’S </i>
          <h3>CERTIFICATIONS</h3>
        </div>
        <div className='py-4'>
          {/* <swiper-container className="mySwiper" pagination="true" pagination-clickable="true" space-between="20" slides-per-view={slidesPerView} centered-slides="false" autoplay-delay="1000" autoplay-disable-on-interaction="false">
            <swiper-slide><img src={certificate1} /></swiper-slide>
            <swiper-slide><img src={certificate2} alt="" /></swiper-slide>
            <swiper-slide><img src={certificate3} /></swiper-slide>
            <swiper-slide><img src={certificate4} alt="" /></swiper-slide>
            <swiper-slide><img src={certificate1} /></swiper-slide>
            <swiper-slide><img src={certificate2} alt="" /></swiper-slide>
            <swiper-slide><img src={certificate3} /></swiper-slide>
            <swiper-slide><img src={certificate4} alt="" /></swiper-slide>
          </swiper-container> */}


          <Swiper
            className="mySwiper"
            pagination={{ clickable: true }}
            spaceBetween={20}
            slidesPerView={slidesPerView}
            centeredSlides={false}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
          >
            <SwiperSlide><img src={certificate10} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate9} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate1} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate2} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate3} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate4} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate5} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate6} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate7} alt="" /></SwiperSlide>
            <SwiperSlide><img src={certificate8} alt="" /></SwiperSlide>
              
          </Swiper>
        </div>
      </div>
    </div>
  </>
}

export const Consultation = () => {
  return <>
    <div className='consultation-overview'>
      <div className="consultation">
        <div className='position-relative'>
          <ContactForm/>
        </div>
      </div>
    </div>
  </>
}

export const OurServices = () => {


  const [slidesPerView, setSlidesPerView] = useState(4); // Default slides per view

  useEffect(() => {
    // Update slidesPerView based on screen width
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setSlidesPerView(1); // Set slides per view to 1 for mobile screens
      } else {
        setSlidesPerView(4); // Set default slides per view for larger screens
      }
    };

    // Initial setup
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>
    <div className="our-services ">
      <div className='container'>
        <div data-aos="fade-right">
          <i >What I Do</i>
          <h3>Consultations</h3>
        </div>

        <div className='our-services-body'>
          <div className='py-4'>


            <Swiper
              className="mySwiper"
              pagination={{ clickable: true }}
              spaceBetween={20}
              slidesPerView={slidesPerView}
              centeredSlides={false}
              autoplay={{ delay: 3000000000000, disableOnInteraction: false }}
            >
              <SwiperSlide>
                <img src={services1} alt="" />
                <div className='services-context-pera'>

                  <div className='services-body'>
                
                  <img src={CLINICAL} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="CLINICAL" />

                    <h5>CLINICAL CONSULTATION </h5>
                    <p style={{ fontSize: '11px', color: '#000000' }}> <li>● Cardiovascular Consultation </li>
                      <li> ● Internal Medicine Consultation </li>
                      <li> ● General Diseases Consultation  </li>
                      <li>●  Personalized care plans Consultation </li>
                      <li>●  Cutting-edge treatment Consultation </li>
                    </p>

                  </div>
                </div>

                <div className='services-context'>
                  <div className='services-body'>
                  <img src={CLINICAL} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="CLINICAL" />


                    <h5>CLINICAL CONSULTATION </h5>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <img src={services2} />
                <div className='services-context-pera'>

                  <div className='services-body'>
                    
                    <img src={INTERVENTIONAL} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="INTERVENTIONAL" />

                    <h5>INTERVENTIONAL CARDIOLOGY </h5>
                    <p style={{ fontSize: '11px', color: '#000000' }}>
                      <li>● Coronary Angiography (CAG) Consultation </li>
                      <li>●  Percutaneous Coronary Intervention (PCI)  </li>
                      <li>● Coronary Total Occultation (CTO) </li>
                      <li>●  Pacemaker Implantation Consultation </li>
                      <li>●  Valvular Heart disease & Ballon Valvuloplasty (PVMV) Consultation </li>
                      <li>● Renal Hypertension including Angiography & Stenting Consultation  </li>
                      <li>● Peripherical Vascular Disease Consultation </li>
                    </p>

                  </div>
                </div>
                <div className='services-context'>
                  <div className='services-body'>
                  <img src={INTERVENTIONAL} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="INTERVENTIONAL" />

                    <h5>INTERVENTIONAL CARDIOLOGY </h5>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <img src={services3} alt="" />
                <div className='services-context-pera'>

                  <div className='services-body'>
                  
                    <img src={CLINICALR} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="CLINICALR" />

                    <h5>CLINICAL RESEARCH </h5>
                    <p style={{ fontSize: '11px', color: '#000000' }}> <li> ● Clinical Research Projects potentiality Consultation </li>
                      <li>●  Clinical Research Project Cooperation</li>
                      <li>●   Clinical Innovation Consultation </li>
                    </p>
                  </div>
                </div>
                <div className='services-context'>
                  <div className='services-body'>
                  <img src={CLINICALR} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="CLINICALR" />


                    <h5>CLINICAL RESEARCH </h5>
                  </div>
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <img src={services4} />
                <div className='services-context-pera'>

                  <div className='services-body'>
                  <img src={MEDICAL} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="MEDICAL" />


                    <h5>MEDICAL ACADEMIC CONSULTATION</h5>
                    <p style={{ fontSize: '11px', color: '#000000' }}> 
                    <li>  ● Non-clinical research projects Consultation </li>
                      <li>● Medical Writing Consultation</li>
                      <li>●  Academic Medical Science consultation through the "Easy Clinical Learning" strategy </li>
                    </p>
                  </div>
                </div>
                <div className='services-context'>
                  <div className='services-body'>
                  <img src={MEDICAL} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="MEDICAL" />


                    <h5>MEDICAL ACADEMIC CONSULTATION</h5>
                  </div>
                </div>
              </SwiperSlide>

              {/* <SwiperSlide>
                <img src={services1} alt="" />
                <div className='services-context-pera'>

                  <div className='services-body'>
                    <FitnessCenterIcon />

                    <h5>Excercise Program</h5>
                    <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                    <button className='btn-two'>Read More</button>
                  </div>
                </div>
                <div className='services-context'>
                  <div className='services-body'>
                    <FitnessCenterIcon />

                    <h5>Balance Body Mind</h5>
                  </div>
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <img src={services2} />
                <div className='services-context-pera'>

                  <div className='services-body'>
                    <FitnessCenterIcon />

                    <h5>Excercise Program</h5>
                    <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                    <button className='btn-two'>Read More</button>
                  </div>
                </div>
                <div className='services-context'>
                  <div className='services-body'>
                    <HealthAndSafetyIcon />
                    <h5>Balance Body Mind</h5>
                  </div>
                </div>
              </SwiperSlide> */}

            </Swiper>



          </div>
        </div>
      </div>
    </div>
  </>
}


export const OurBlog = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(getblogelement())
      .then((response) => {
        // Sort data by created_at field in descending order
        const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  
        // Take the first three elements
        const latestThreeData = sortedData.slice(0, 3);
  
        // Format data
        const formattedData = latestThreeData.map((e) => ({
          grid: 'col-md-4',
          img: e.image,
          lbbutton: e.title,
          p: <p><span>{e.created_at}</span><span>10 Comments</span></p>,
          h5: e.heading,
          content: e.description,
          id: e.id // Add ID to the data
        }));
  
        setData(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  
  return <>
    <div className="container ">
      <div className='text-center'>
        <i>From Blog</i>
        <h3>NEWS AND ARTICLES</h3>
      </div>
      <div className="row py-3">
      {data && data.map(res => {
        return <div  key={res.id}  className={res.grid}>
        <Link to={`/BlogDetails/${res.id}`}>
          <div className="blog-box">
            <div className='blog-img'>
              <img src={res.img} alt="" />
              <div className="blog-label">
                {res.icon}
                <div className='blog-lb-btn'>
                  {res.lbbutton}
                </div>
              </div>
            </div>
            <div className='blog-content'>
              {res.p}
              <h5>{res.h5}</h5>
              {/* <p>{res.content}</p> */}
            </div>
          </div>
          </Link>
        </div>

      })}

    </div>
      {/* <News data={newsdata} /> */}
    </div>
  </>
}

const newsdata = [
  {
    grid: 'col-md-4',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-4',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-4',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  }


]

export const News = ({ data }) => {
  
  return <>
    <div className="row py-3">
      {data && data.map(res => {
        return <div  key={res.id}  className={res.grid}>
          <div className="blog-box">
            <div className='blog-img'>
              <img src={res.img} alt="" />
              <div className="blog-label">
                {res.icon}
                <div className='blog-lb-btn'>
                  {res.lbbutton}
                </div>
              </div>
            </div>
            <div className='blog-content'>
              {res.p}
              <h5>{res.h5}</h5>
              <p>{res.content}</p>
            </div>
          </div>
        </div>

      })}

    </div>

  </>
}


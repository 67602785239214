import { Share } from '@mui/icons-material';
import { Divider } from '@mui/material';
import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PosterOne = ({ img, title }) => {
    const handleShare = async () => {
        const host = window.location.hostname;  // Get the host
        const port = window.location.port;      // Get the port
        const pathname = window.location.pathname; // Get the pathname
        const protocol = window.location.protocol; // Get the protocol
        const url = `${protocol}//${host}${port ? `:${port}` : ''}${pathname}`; // Construct the URL

        const shareData = {
            title: title,
            text: `Check out this post: ${title}`,
            url: url,
        };

        try {
            if (navigator.share) {
                await navigator.share(shareData);
                console.log('Shared successfully');
            } else {
                await navigator.clipboard.writeText(url);
                console.log('URL copied to clipboard');
            }
        } catch (err) {
            console.error('Error sharing:', err);
        }
    };

    return (
        <div>
            <div className='make-appointment'>
                <img src={img} alt="" />
                <div className="text-container">
                    <div>
                        <h1 style={{ fontWeight: "bolder", fontSize: "3rem" }}>{title}</h1>
                    </div>
                </div>
            </div>
            <div className='container p-3 posterone-share'>
                <div className="cmd">
                    <div>
                        Home <span><ChevronRightIcon/> {title} </span>
                    </div>
                    <div  onClick={handleShare} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>

                        <Share /><span>Share</span>
                    </div>
                </div>
            </div>
            <Divider sx={{borderColor:"#dcdcdc",marginBottom:"1rem"}}/>
        </div>
    );
};

export default PosterOne;

import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deleteblogelement, getblogelement, postblogelement, updateblogelement } from '../../Services/constant';
import axios from 'axios';
import SnackbarAlert from '../../mycomponent/share/component.jsx/snackbar';
import BasicTable from '../components/muiTable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AdminBlog = () => {
    const [heading, setHeading] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [galleryImage, setGalleryImage] = useState(null);

    const [active, setActive] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [alertMessage, setAlertMessage] = useState('success');

    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null); // New state for edit ID

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'heading', label: 'Heading' },
        { id: 'description', label: 'Description' },
        { id: 'action', label: 'Action' }
    ];

    const fatch_blogsdata = () => {
        setData([]);
        axios.get(getblogelement())
            .then((response) => {
                response.data.data.map((e) => {
                    setData((obj) => {
                        return [...obj,
                        {
                            id: e.id,
                            heading: e.heading,
                            title: e.title,
                            sub_title: e.sub_title,
                            description: e.description
                        }]
                    });
                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        fatch_blogsdata();
    }, []);

    useEffect(() => {
        setActive(false);
    }, [active]);

    const handlePostRequest = () => {
        if (!heading || !title || !subTitle || !description) {
            setActive(true);
            setAlertType('error');
            setAlertMessage('Please fill all the credentials');
        } else {
            const formData = new FormData();
            formData.append('heading', heading);
            formData.append('title', title);
            formData.append('sub_title', subTitle);
            formData.append('description', description);
            if (galleryImage) {
                formData.append('image', galleryImage);
            }

            const endpoint = editId ? updateblogelement() : postblogelement();
            const requestData = editId ? { id: editId, ...Object.fromEntries(formData.entries()) } : formData;

            axios.post(endpoint, requestData)
                .then((response) => {
                    setActive(true);
                    setAlertType('success');
                    setAlertMessage(editId ? 'Update Successfully' : 'Submit Successfully');

                    // Clear the form fields
                    setGalleryImage(null);
                    setDescription('');
                    setSubTitle('');
                    setHeading('');
                    setTitle('');
                    setEditId(null); // Reset editId after successful update

                    fatch_blogsdata();
                })
                .catch(error => {
                    setActive(true);
                    setAlertType('error');
                    setAlertMessage('Error occurred while submitting the form');
                });
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setGalleryImage(file);
    };

    const handleEdit = (row) => {
        setEditId(row.id);
        setHeading(row.heading);
        setTitle(row.title);
        setSubTitle(row.sub_title);
        setDescription(row.description);
    };

    const ActionCell = ({ row, onEdit }) => {
        const deleteHandler = () => {
            axios.post(deleteblogelement(), { id: row.id })
                .then(() => {
                    fatch_blogsdata();
                });
        }

        return <>
            <Tooltip>
                <IconButton onClick={deleteHandler}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip>
                <IconButton onClick={() => onEdit(row)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
        </>
    }

    return (
        <div>
            <div className=''>
                <SnackbarAlert active={active} type={alertType} message={alertMessage} />
                <form>
                    <h1>Admin Blog</h1>
                    <div className="row">
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Title" variant="outlined" value={title} onChange={(e) => { setTitle(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Sub-Title" variant="outlined" value={subTitle} onChange={(e) => { setSubTitle(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Heading" variant="outlined" value={heading} onChange={(e) => { setHeading(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Description" variant="outlined" value={description} onChange={(e) => { setDescription(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField type='file' id="outlined-basic" label="Image" variant="outlined" onChange={handleFileChange} />
                        </div>
                    </div>
                    <div className="cmd">
                        <div></div>
                        <Button onClick={handlePostRequest} variant="contained">Submit</Button>
                    </div>
                </form>

                <BasicTable
                    rows={data}
                    columns={columns}
                    ActionCell={({ row }) => <ActionCell row={row} onEdit={handleEdit} />}
                />
            </div>
        </div>
    );
}

export default AdminBlog;

// // validation url base
// export const forgotPath = '/api/user/reset/*';
// export const register_InvitePath = '/api/user/invite/*';
// export const baseUrlPath = 'app.eslrankspro.com';

// root path for this project
export const nopage = '*';
export const home = '/';
export const about = 'about';
export const gallery = 'gallery';
export const login = 'login';
export const contact = 'contact';
export const blog = 'blogs';
export const videos = 'videos';
export const services = 'consultations';
export const research = 'publications';
export const clinical = 'easy-clinical-learning';
export const product = 'others';
export const consultation = 'consultation';
export const blogdetails = 'blogdetails';
export const healthcare = 'healthcare';
export const clinicaldetails = 'clinicaldetails';
export const researchdetails = 'researchdetails';
export const healthcaredetails = 'healthcaredetails';
export const otherdetails = 'otherdetails';
export const researchcard = 'research';


export const admin = 'admin';
export const dashboard = '/admin/home';
export const adminblog= '/admin/blog'
export const adminblognew= '/admin/blog-new'
export const adminvideo= '/admin/video'
export const adminmedical= '/admin/product'
export const admingallery= '/admin/gallery'
export const adminclinical = '/admin/clinical-learning'
export const adminreasearchform = '/admin/reasearchform'
export const contactreport = '/admin/contact-report'


 import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Sidebar } from './components/sidebar'
import TopBar from './components/topBar'

const Admin_layout = () => {

    return (
        <>
            <div className="">

                <>
                    <TopBar />
                    <div className='d-flex'>
                        <Sidebar />
                        <div className="admin_body"><Outlet /></div>
                    </div>
                </>



            </div>
        </>
    )
}






export default Admin_layout;
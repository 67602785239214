import React, { useEffect, useState } from 'react';
import aboutbanner from '../assets/aboutbanner.jpg';
import PosterOne from '../share/component.jsx/posterone';
import { Consultation, OurCertificate, OurVision } from '.';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import boygirl from '../assets/boygirl.jpg';
import img1 from '../assets/skill/1.png'
import img2 from '../assets/skill/2.png'
import img3 from '../assets/skill/3.png'
import img4 from '../assets/skill/4.png'
import img5 from '../assets/skill/5.png'
import img6 from '../assets/skill/6.png'
import img7 from '../assets/skill/7.png'
import img8 from '../assets/skill/8.png'

import image1 from '../assets/img1.jpeg';
import image2 from '../assets/img2.png';
import image3 from '../assets/img3.jpeg';

import academic from '../assets/icon/Academic.png';
import Clinical from '../assets/icon/Clinical.png';
import Consulting from '../assets/icon/consultant.png';
import Healthcare from '../assets/icon/healthcare.png';
import Heart from '../assets/icon/electrocardiogram.png';
import Research from '../assets/icon/research.png';
import Language from '../assets/icon/Language.png';

import icon1 from '../assets/connectus/1.png'
import icon2 from '../assets/connectus/2.png'
import icon3 from '../assets/connectus/3.png'
import icon4 from '../assets/connectus/4.png'
import icon5 from '../assets/connectus/5.png'
import icon6 from '../assets/connectus/6.png'
import icon7 from '../assets/connectus/7.png'
import icon8 from '../assets/connectus/8.png'
import icon9 from '../assets/connectus/9.png'
import icon10 from '../assets/connectus/10.png'
import icon11 from '../assets/connectus/11.png'
import icon12 from '../assets/connectus/12.png'

import team3 from '../assets/team3.jpg'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { API_BASE_URL, API_ENDPOINTS } from '../../Services/constant';
import axios from 'axios';
const About = () => {



  return (
    <>
      <PosterOne img={aboutbanner} title={'About Dr. Siam Al Mobarak'} />
      {/* <OurVision /> */}
      < div className='our-vision ' >
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div data-aos="fade-left">
                <i >Some story behind </i>
                <h3>DR. SIAM AL MOBARAK </h3>
                <p>I am a cardiology clinical physician with Cath-Lab experience in Interventional Cardiology, have published research papers, and am familiar with EUMDR, FDA, ISO, and NPMA regulations.</p>

                <div className='vision-mission '>
                  <div className="row">
                    <div className="col-md-6 ">
                      <h4> <strong> My Mission</strong></h4>
                      <p>
                        My mission is simple yet profound: to provide exceptional clinical care and extensive research that not only saves lives but also enhances their quality. With a background deeply rooted in clinical research and consultancy with a dedication to continuous improvement, I aim to make a lasting impact on the health of our patients.

                      </p>
                    </div>
                    <div className="col-md-6 " style={{ textAlign: "left" }}>
                      <h4> <strong> My Vision</strong></h4>
                      <p>
                        My vision is to redefine clinical care through consultancy, research, innovation, expertise, and compassion.  I aspire to be a beacon of hope for those facing clinical care and healthcare challenges, offering cutting-edge treatments, personalized care plans, consultancy, and unwavering support.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className='vision-img' data-aos="fade-right">
                <img src={boygirl} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div >
      <div className='choosing-us '>
        <div className='container'>
          <div className='text-center' data-aos="fade-right">
            <i>My Qualification</i>
            <h3>DR. SIAM AL MOBARAK SKILLS</h3>
          </div>
          <div>
            <h4><strong>Professional Skills:</strong>   </h4>
          </div>

          <div className="row py-4">
            <div className="col-md-4">
              <div className="choosing-us-box">

                <h5>Academic </h5>
                <div className='d-flex align-items-center'>
                  <img src={academic} style={{ width: "49px", marginRight: "1rem" }} alt="academic" />

                  <p>A skilled medical science instructor with an "Easy Clinical Learning" strategy for complex medical topics to the clinical learners.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Clinical </h5>
                <div className='d-flex align-items-center'>
                  <img src={Clinical} style={{ width: "49px", marginRight: "1rem" }} alt="Clinical" />

                  <p>Specialized Clinical Physician majoring in Cardiovascular Systems with Cath-Lab surgical experience.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Consulting </h5>
                <div className='d-flex align-items-center'>
                  <img src={Consulting} style={{ width: "49px", marginRight: "1rem" }} alt="Consulting" />

                  <p>An expert in Clinical & health care through "Cutting-edge treatments, personalized care plans and unwavering support." </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Healthcare </h5>
                <div className='d-flex align-items-center'>
                  <img src={Healthcare} style={{ width: "49px", marginRight: "1rem" }} alt="Healthcare" />

                  <p><br />Expertise as a Product Development Consultant for multinational healthcare organizations to ensure that, the organization's innovations meet the safety & performance and business requirements of "EUMDR, FDA, ISO, and NPMA" standards.
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Interventional Cardiology </h5>
                <div className='d-flex align-items-center'>
                  <img src={Heart} style={{ width: "49px", marginRight: "1rem" }} alt="Heart" />

                  <p>Clinical physician with Cath-Lab surgical experience in "Coronary Angiography (CAG), Percutaneous Coronary Intervention (PCI), Coronary Total Occultation (CTO), Pacemaker Implantation Surgeries, Ballon Valvuloplasty (PVMV) and assisted Renal Angiography & Stenting."</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Research</h5>
                <div className='d-flex align-items-center'>
                  <img src={Research} style={{ width: "49px", marginRight: "1rem" }} alt="Research" />

                  <p> <br /><br />Multiple clinical and non-clinical research publications have been published in collaboration with other teams. <br /><br /><br /></p>
                </div>
              </div>
            </div>
            <div>
              <br /> <br />
              <h4> <strong> Language Skills: </strong>  </h4>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Bengali </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />

                  <p>Mother Tongue</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Chinese </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Woking Proficiency</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>English </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Woking Proficiency</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Hindi </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Communication Proficiency</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Italian </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Beginner's Proficiency</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="choosing-us-box">
                <h5>Urdu  </h5>
                <div className='d-flex align-items-center'>
                  <img src={Language} style={{ width: "49px", marginRight: "1rem" }} alt="Language" />
                  <p>Communication Proficiency</p>
                </div>
              </div>
            </div>




          </div>
        </div>
      </div>

      <div className='successfull-experience'>
        <div className="successfull-body container">
          <div>
            <i>Interesting Facts</i>
            <h3>SUCESSFULL EXPERINCE</h3>
          </div>
          <div className="row my-4">
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="succ-experi-box">
                <div className="count">
                  <h1>01</h1>
                </div>
                <img src={img1} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="CLINICALR" />
                <h2>MULTIPLE</h2>
                <p>CLINICAL & HEALTHCARE CONSULTATION</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="succ-experi-box">
                <div className="count">
                  <h1>02</h1>
                </div>
                <img src={img2} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />

                <h2>500+</h2>
                <p>CORONARY ANGIOGRAPHY (CAG) & RENAL ANGIOGRAPHY</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="succ-experi-box">
                <div className="count">
                  <h1>03</h1>
                </div>
                <img src={img3} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="" precutaneous />
                <h2>100+</h2>
                <p>PERCUTANEOUS CORONARY INTERVENTION (PCI) SURGERIES</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="succ-experi-box">
                <div className="count">
                  <h1>04</h1>
                </div>
                <img src={img4} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />

                <h2>20+</h2>
                <p>CORONARY TOTAL OCCULATION (CTO)</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="succ-experi-box">
                <div className="count">
                  <h1>05</h1>
                </div>
                <img src={img5} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="pacemaker" />
                <h2>10+</h2>
                <p>PACEMAKER IMPLANTATION SURGERIES</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="succ-experi-box">
                <div className="count">
                  <h1>06</h1>
                </div>
                <img src={img6} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="ballon" />
                <h2>5+</h2>
                <p>BALLOON VALVULOPLASTY (PVMV) SURGERIES</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="succ-experi-box">
                <div className="count">
                  <h1>07</h1>
                </div>
                <img src={img7} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="clinical" />

                <h2>10+</h2>
                <p>CLINICAL<br /> PUBLICATION</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="succ-experi-box">
                <div className="count">
                  <h1>08</h1>
                </div>
                <img src={img8} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="" />
                <h2>MULTIPLE</h2>
                <p>MEDICAL WRITING & ACADEMIC CONSULTATION</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="how-it-works  ">
        <div className="container">
          <div>
            <i>How it Works?</i>
            <h3>PROCESS FOR HEALTHY LIFE</h3>
          </div>
          <div className="row py-4">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="2000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon1} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>1</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>BALANCED DIET</h3>
                  <p>Choose whole grains, fruits, vegetables, lean meats, healthy fats, and low-carbohydrate foods. </p>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="2500">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon2} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>2</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>STAY HYDRATED</h3>
                  <p>Drink plenty of water and avoid sugary drinks.</p>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon3} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>3</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>GO NATURAL</h3>
                  <p>Consume more green foods and less processed foods, sugar, and unhealthy fats.</p>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon4} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />

                  </div>
                  <div class="count">
                    <h3>4</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>SCHEDULE EXERCISE</h3>
                  <p>Aim for at least 150 minutes of moderate-intensity exercise per week. </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon5} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>5</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>MAINTAIN WEIGHT</h3>
                  <p>Maintaining a healthy body mass index (BMI) can help reduce your chance of acquiring chronic illnesses.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon6} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>6</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3> QUIT SMOKE & ALCOHOL</h3>
                  <p>Quitting smoking & and alcohol consumption are the best thing you can do for your health. </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon7} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>7</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>GET ENOUGH SLEEP</h3>
                  <p>Get 7 to 8 hours of sleep every night. </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon8} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>8</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>MANAGE STRESS</h3>
                  <p>Engage in stress-reduction exercises such as yoga, deep breathing, or meditation. </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon9} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>9</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>PRACTICE GOOD HYGIENE</h3>
                  <p>Keep your living environment clean, wash your hands frequently, and cover your mouth and nose while coughing or sneezing.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon10} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>10</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>MAINTAIN SOCIAL CONNECTIONS</h3>
                  <p>Having deep ties with friends, family, and other people can improve your happiness and general well-being. </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon11} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>11</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>REGULAR HEALTH CHECK-UP</h3>
                  <p>Stay up-to-date with recommended health screenings and check-ups.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="it-works-body" data-aos="fade-up" data-aos-duration="3000">
                <div className="it-icon-box">
                  <div class="icon">
                    <img src={icon12} style={{ width: "50px", height: "50px", display: "inline-block" }} alt="BALANCED DIET" />
                  </div>
                  <div class="count">
                    <h3>12</h3>
                  </div>
                </div>
                <div className="it-content-box">
                  <h3>CONNECT WITH US</h3>
                  <p>Don't ignore symptoms or delay seeking medical care when necessary. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Testimonials notech_clip">
        <div className="container">
          <div className="cmd">
            <div>
              <i>Achievements</i>
              <h3 className='mb-4'>Awards</h3>
            </div>
            {/* <button className='btn-one'>MORE STORIES</button> */}
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testimonial-box" data-aos="zoom-in">
                <div className="testimonial-img">
                  <img src={image1} alt="" />
                </div>
                <i></i>
                <p>
                  My biography in China was broadcast on Guangxi TV and Guangxi News, demonstrating not only my clinical but also surgical knowledge in the Cath-Lab.
                </p>
                <h5>	TV Interview</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testimonial-box" data-aos="zoom-in">
                <div className="testimonial-img1">
                  <img src={image2} alt="" />
                </div>
                <i></i>
                <p>
                  Awarded as "China Scholarship Council (CSC)" funded International Student scholarship for 3 years Master’s Degree in Cardiology.

                </p>
                <h5>	Awarded Scholarship</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testimonial-box" data-aos="zoom-in">
                <div className="testimonial-img">
                  <img src={image3} alt="" />
                </div>
                <i></i>
                <p>
                  Cardiology Case Report Competition Winner 2016.
                </p>
                <h5>Competition Winner</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="our-team container">
        <div>
          <i>Best Coachers</i>
          <h3>MEET OUR TEAM</h3>
        </div>

        <div className="row">
          <div className='col-lg-4 col-md-4 col-sm-4 col-xs-12'>
            <div className="our-team-body">
              <div className="team-img-box">
                <img src={team1} alt="" />

                <div class="overlay-style-one">
                  <div class="content">
                    <ul>
                      <li><FacebookRoundedIcon /></li>
                      <li><TwitterIcon /></li>
                      <li><LinkedInIcon /></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-content">
                <h4>Steve Bairstow</h4>
                <i>CEO & Founder</i>
                <p>How all this mistaken idea of denouncing pleasure and praising pain was born and I will complete account of the system.</p>
              </div>
            </div>

          </div>

          <div className='col-lg-4 col-md-4 col-sm-4 col-xs-12'>
            <div className="our-team-body">
              <div className="team-img-box">
                <img src={team2} alt="" />

                <div class="overlay-style-one">
                  <div class="content">
                    <ul>
                      <li><FacebookRoundedIcon /></li>
                      <li><TwitterIcon /></li>
                      <li><LinkedInIcon /></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-content">
                <h4>Steve Bairstow</h4>
                <i>CEO & Founder</i>
                <p>How all this mistaken idea of denouncing pleasure and praising pain was born and I will complete account of the system.</p>
              </div>
            </div>

          </div>

          <div className='col-lg-4 col-md-4 col-sm-4 col-xs-12'>
            <div className="our-team-body">
              <div className="team-img-box">
                <img src={team3} alt="" />

                <div class="overlay-style-one">
                  <div class="content">
                    <ul>
                      <li><FacebookRoundedIcon /></li>
                      <li><TwitterIcon /></li>
                      <li><LinkedInIcon /></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-content">
                <h4>Steve Bairstow</h4>
                <i>CEO & Founder</i>
                <p>How all this mistaken idea of denouncing pleasure and praising pain was born and I will complete account of the system.</p>
              </div>
            </div>

          </div>
        </div>

      </div> */}


      <Consultation />
      <br /><br /><br /><br /> <br />
      <OurCertificate />


    </>
  );
};

export default About;



import React, { useEffect } from 'react'
import NavigationIcon from '@mui/icons-material/Navigation';
const Scrooltop = () => {
    useEffect(() => {
        const handleScroll = () => {
            const scrollButton = document.querySelector('.scroll-to-top');
            if (window.scrollY >= 100) {
                scrollButton.style.display = 'block';
            } else {
                scrollButton.style.display = 'none';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            <div class="scroll-to-top scroll-to-target" data-target="html" onClick={() => { window.scrollTo(0, 0); }}><NavigationIcon /></div>
        </div>
    )
}

export default Scrooltop;
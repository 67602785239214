import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deletegalleryitem, getgalleryitem, postgalleryitem } from '../../Services/constant';
import axios from 'axios';
import SnackbarAlert from '../../mycomponent/share/component.jsx/snackbar';
import BasicTable from '../components/muiTable';
import DeleteIcon from '@mui/icons-material/Delete';







const AdminGallery = () => {




    const [heading, setHeading] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [galleryImage, setGalleryImage] = useState(null);


    const [active, setActive] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('success')

    const [data, setData] = useState([])

    const columns = [
        {
            id: 'id',
            label: 'ID'
        },
        {
            id: 'heading',
            label: 'Heading'
        },
        {
            id: 'description',
            label: 'Description'
        },

        {
            id: 'action',
            label: 'Action'
        }
    ]


    const fatch_data = () => {
        setData([])
        axios.get(getgalleryitem())
            .then((response) => {

                response.data.data.map((e) => {
                    setData((obj) => {
                        return [...obj,
                        {
                            id: e.id,
                            heading: e.heading,
                            description: e.description
                            // img: e.image,
                            // lbbutton: e.title,
                            // p: <p><span>{e.created_at}</span><span>10 Comments</span></p>,
                            // h5: e.heading,
                            // content: e.description
                        }

                        ]
                    })

                })

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(()=>{
        fatch_data()
    },[])

    useEffect(() => {
        setActive(false)
    }, [active])



    const formData = new FormData();
    formData.append('heading', heading);
    formData.append('title', title);
    formData.append('sub_title', subTitle);
    formData.append('description', description);
    formData.append('image', galleryImage);

    const handlePostRequest = () => {
        if (!heading || !title || !subTitle || !description || !galleryImage) {
            setActive(true)
            setAlertType('error')
            setAlertMessage('Please fill all the credentials')
        }
        else {
            axios.post(postgalleryitem(), formData)
                .then((response) => {
                    setActive(true)
                    setAlertType('success')
                    setAlertMessage('Submit Successfully')

                    setGalleryImage('')
                    setDescription('')
                    setSubTitle('')
                    setHeading('')
                    setTitle('')
                })
        }

    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setGalleryImage(file)
    };

    const ActionCell = ({ row }) => {

        const deleteHandler = () => {
            axios.post(deletegalleryitem(), { id: row.id })
                .then(() => {
                    fatch_data()
                })
        }



        return <>
            <Tooltip>
                <IconButton onClick={deleteHandler}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    }

    return (
        <div>
            <div className=''>
                <SnackbarAlert active={active} type={alertType} message={alertMessage} />
                <form>
                    <h1>Admin Gallery</h1>
                    <div className="row">
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Title" variant="outlined" value={title} onChange={(e) => { setTitle(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Sub-Title" variant="outlined" value={subTitle} onChange={(e) => { setSubTitle(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Heading" variant="outlined" value={heading} onChange={(e) => { setHeading(e.target.value); }} />
                        </div>

                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Description" variant="outlined" value={description} onChange={(e) => { setDescription(e.target.value); }} />
                        </div>

                        <div className="col-md-4 mt-4">
                            <TextField type='file' id="outlined-basic" label="Image" variant="outlined" onChange={handleFileChange} />
                        </div>
                    </div>
                    <div className="cmd ">
                        <div></div>
                        <Button onClick={handlePostRequest} variant="contained">Submit</Button>
                    </div>
                </form>

                <BasicTable
                    rows={data}
                    columns={columns}
                    ActionCell={ActionCell}
                />

            </div>
        </div>
    )
}

export default AdminGallery;
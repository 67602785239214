import React from 'react'
import { NavLink } from 'react-router-dom'
import "../css/sidebar.css"
import DashboardIcon from '@mui/icons-material/Dashboard';
import VideocamIcon from '@mui/icons-material/Videocam';
import CollectionsIcon from '@mui/icons-material/Collections';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { admin, adminblog, adminblognew, adminclinical, admingallery, adminmedical, adminreasearchform, adminvideo, contactreport, dashboard } from '../../Navigation/constant';

const sidebardata = [
    {
        title: 'Dashboard',
        path: dashboard,
        icon: <DashboardIcon />
    },
    {
        title: 'Gallery',
        path: admingallery,
        icon: <CollectionsIcon/>
    },
    {
        title: 'Video',
        path: adminvideo,
        icon: <VideocamIcon />
    },
    {
        title: 'Blogs',
        path: adminblognew,
        icon: <ConnectedTvIcon />
    },
    {
        title: ' Learning Zone',
        path: adminclinical,
        icon: <LocalHospitalIcon />
    },
    {
        title: 'contact-report',
        path: contactreport,
        icon: <LocalHospitalIcon />
    }


]

export const Sidebar = () => {
    return (
        <>
            <div className="Sidebar">
                <ul>
                    {sidebardata.map(res => {
                        return <li><NavLink to={res.path}> {res.icon}{res.title}</NavLink></li>
                    })}
                </ul>
            </div>



        </>
    )
}

import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deletevideoelement, getvideoelement, postvideoelement } from '../../Services/constant';
import axios from 'axios';
import SnackbarAlert from '../../mycomponent/share/component.jsx/snackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import BasicTable from '../components/muiTable';

const AdminVideo = () => {


    const [inputDescription, setInputDescription] = useState('');
    const [inputDatatittle, setInputDatatittle] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const [videoImage, setVideoImage] = useState(null);

    const [inputheading, setInputheading] = useState('');
    const [inputsub_title, setInputsub_title] = useState('');
    const [inputyoutube_linke, setInputyoutube_linke] = useState('');
    const [inputlinkedin_link, setInputlinkedin_link] = useState('');


    const [active, setActive] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('success')
    const [data, setData] = useState([])

    const columns = [
        {
            id: 'id',
            label: 'ID'
        },
        {
            id: 'heading',
            label: 'Heading'
        },
        {
            id: 'description',
            label: 'Description'
        },

        {
            id: 'action',
            label: 'Action'
        }
    ]


    const fatch_blogsdata = () => {
        setData([])
        axios.get(getvideoelement())
            .then((response) => {

                response.data.data.map((e) => {
                    setData((obj) => {
                        return [...obj,
                        {
                            id: e.id,
                            heading: e.heading,
                            description: e.description
                            // img: e.image,
                            // lbbutton: e.title,
                            // p: <p><span>{e.created_at}</span><span>10 Comments</span></p>,
                            // h5: e.heading,
                            // content: e.description
                        }

                        ]
                    })

                })

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        fatch_blogsdata()
    }, [])

    useEffect(() => {
        setActive(false)
    }, [active])

    const handlePostRequest = () => {
        if (!inputDescription || !inputDatatittle || !videoFile || !videoImage || !inputheading || !inputsub_title || !inputyoutube_linke || !inputlinkedin_link) {
            setActive(true)
            setAlertType('error')
            setAlertMessage('Please fill all the credentials')
        }
        else {
            const formData = new FormData();
            formData.append('title', inputDatatittle);
            formData.append('description', inputDescription);
            formData.append('video', videoFile);
            formData.append('image', videoImage);
            formData.append('heading', inputheading);
            formData.append('sub_title', inputsub_title);
            formData.append('youtube_link', inputyoutube_linke);
            formData.append('linkedin_link', inputlinkedin_link);

            axios.post(postvideoelement(), formData)
                .then((response) => {
                    console.log(response);
                    setActive(true)
                    setAlertType('success')
                    setAlertMessage('Submit Successfully')

                    setInputDescription('');
                    setInputDatatittle('');
                    setVideoFile(null);
                    setVideoImage(null);

                    setInputheading('');
                    setInputsub_title('');
                    setInputyoutube_linke('');
                    setInputlinkedin_link('');

                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setVideoFile(file);
    };

    const handleFileChangeImage = (e) => {
        const file = e.target.files[0];
        setVideoImage(file);
    };


    const ActionCell = ({ row }) => {

        const deleteHandler = () => {
            axios.post(deletevideoelement(), { id: row.id })
                .then(() => {
                    fatch_blogsdata()
                })
        }



        return <>
            <Tooltip>
                <IconButton onClick={deleteHandler}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    }

    return (
        <div>
            <SnackbarAlert active={active} type={alertType} message={alertMessage} />

            <div className=''>
                <form>
                    <h1>Admin Video</h1>
                    <div className="row">
                        <div className="col-md-4 mt-4">
                            <TextField type='file' accept="video/*" id="outlined-basic" label="Video" variant="outlined" onChange={handleFileChange} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField type='file' accept="image/*" id="outlined-basic" label="Image" variant="outlined" onChange={handleFileChangeImage} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Heading" variant="outlined" value={inputheading} onChange={(e) => { setInputheading(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Title" variant="outlined" value={inputDatatittle} onChange={(e) => { setInputDatatittle(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Sub-Title" variant="outlined" value={inputsub_title} onChange={(e) => { setInputsub_title(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Description" variant="outlined" value={inputDescription} onChange={(e) => { setInputDescription(e.target.value); }} />
                        </div>

                        <div className="col-md-4 mt-4">
                            <TextField type='text' id="outlined-basic" label="YouTube Link" variant="outlined" value={inputyoutube_linke} onChange={(e) => { setInputyoutube_linke(e.target.value); }} />
                        </div>

                        <div className="col-md-4 mt-4">
                            <TextField type='text' id="outlined-basic" label="LinkedIn Link" variant="outlined" value={inputlinkedin_link} onChange={(e) => { setInputlinkedin_link(e.target.value); }} />
                        </div>



                    </div>

                    <div className="cmd">
                        <div></div>
                        <Button onClick={handlePostRequest} variant="contained">Submit</Button>
                    </div>
                </form>

                <BasicTable
                    rows={data}
                    columns={columns}
                    ActionCell={ActionCell}
                />
            </div>
        </div>
    );
}

export default AdminVideo;

import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deleteClinicalLearningelement, getClinicalLearningelement, postClinicalLearningelement, updateClinicalLearningelement } from '../../Services/constant';
import axios from 'axios';
import SnackbarAlert from '../../mycomponent/share/component.jsx/snackbar';
import BasicTable from '../components/muiTable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AdminClinicalLearningZone = () => {
    const [type, setType] = useState(1);
    const [heading, setHeading] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [galleryImage, setGalleryImage] = useState(null);
    const [active, setActive] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [alertMessage, setAlertMessage] = useState('success');
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'heading', label: 'Heading' },
        { id: 'description', label: 'Description' },
        { id: 'action', label: 'Action' }
    ];

    const fetch_data = () => {
        setData([]);
        axios.get(getClinicalLearningelement())
            .then((response) => {
                response.data.data.map((e) => {
                    setData((obj) => [...obj, {
                        id: e.id,
                        heading: e.heading,
                        title: e.title,
                        sub_title: e.sub_title,
                        description: e.description
                    }]);
                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        fetch_data();
    }, []);

    useEffect(() => {
        setActive(false);
    }, [active]);

    const handlePostRequest = () => {
        if (!heading || !title || !subTitle || !description) {
            setActive(true);
            setAlertType('error');
            setAlertMessage('Please fill all the credentials');
        } else {
            const formData = new FormData();
            formData.append('type', type);
            formData.append('heading', heading);
            formData.append('title', title);
            formData.append('sub_title', subTitle);
            formData.append('description', description);
            if (galleryImage) {
                formData.append('image', galleryImage);
            }
            formData.append('link', ['N/A']);
            formData.append('video', ['N/A']);

            const endpoint = editId ? updateClinicalLearningelement() : postClinicalLearningelement();
            const requestData = editId ? { id: editId, ...Object.fromEntries(formData.entries()) } : formData;

            axios.post(endpoint, requestData)
                .then((response) => {
                    setActive(true);
                    setAlertType('success');
                    setAlertMessage(editId ? 'Update Successfully' : 'Submit Successfully');

                    // Clear the form fields
                    setGalleryImage(null);
                    setDescription('');
                    setSubTitle('');
                    setHeading('');
                    setTitle('');
                    setEditId(null);

                    fetch_data();
                })
                .catch(error => {
                    setActive(true);
                    setAlertType('error');
                    setAlertMessage('Error occurred while submitting the form');
                });
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setGalleryImage(file);
    };

    const handleEdit = (row) => {
        setEditId(row.id);
        setType(row.type);
        setHeading(row.heading);
        setTitle(row.title);
        setSubTitle(row.sub_title);
        setDescription(row.description);
    };

    const ActionCell = ({ row }) => {
        const deleteHandler = () => {
            axios.post(deleteClinicalLearningelement(), { id: row.id })
                .then(() => {
                    fetch_data();
                });
        }

        return (
            <>
                <Tooltip>
                    <IconButton onClick={deleteHandler}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip>
                    <IconButton onClick={() => handleEdit(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    return (
        <div>
            <div className=''>
                <SnackbarAlert active={active} type={alertType} message={alertMessage} />
                <form>
                    <h1>Admin Learning Zone</h1>
                    <div className="row">
                        <div className="col-md-4 mt-4">
                            <FormControl className='' sx={{ width: '100%' }}>
                                <InputLabel id={`label`}>{'Select Type'}</InputLabel>
                                <Select
                                    labelId={`label`}
                                    id={`select`}
                                    value={type}
                                    label={'Select Type'}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <MenuItem value={4}>Research</MenuItem>
                                    <MenuItem value={1}>Easy Clinical Learning</MenuItem>
                                    <MenuItem value={2}>Health Care</MenuItem>
                                    <MenuItem value={3}>Others</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Sub-Title" variant="outlined" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Heading" variant="outlined" value={heading} onChange={(e) => setHeading(e.target.value)} />
                        </div>
                        <div className="col-md-12 mt-4">
                            <CKEditor
                                editor={ClassicEditor}
                                data={description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                }}
                            />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField type='file' id="outlined-basic" label="Image" variant="outlined" onChange={handleFileChange} />
                        </div>
                    </div>
                    <div className="cmd">
                        <div></div>
                        <Button onClick={handlePostRequest} variant="contained">Submit</Button>
                    </div>
                </form>

                <BasicTable
                    rows={data}
                    columns={columns}
                    ActionCell={({ row }) => <ActionCell row={row} />}
                />
            </div>
        </div>
    );
}

export default AdminClinicalLearningZone;

import React, { useEffect, useState } from 'react'
import PosterOne from '../share/component.jsx/posterone';
import aboutbanner from '../assets/aboutbanner.jpg';
import video1 from '../video/horse.mp4';
import blog1 from '../assets/blog1.jpg'
import services1 from '../assets/services1.jpg'
import services2 from '../assets/services2.jpg'
import services3 from '../assets/services3.jpg'
import services4 from '../assets/services4.jpg'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

import { VideoPlaySection, VideoPlayerShowCase } from './video';
import { API_BASE_URL, API_ENDPOINTS_galleries, getgalleryitem } from '../../Services/constant';
import axios from 'axios';



const itemData = [
    {

        img: services1,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'

    },
    {

        img: services2,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'

    },
    {

        img: services3,
        title: 'Happy Young Family',
        author: 'Healthy Food',

        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
    },
    {

        img: services4,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'

    },
    {

        img: services1,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'

    },
    {

        img: services2,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'


    },

];

const Gallery = () => {


    const [data, setData] = useState([]);

    useEffect(() => {


        axios.get(getgalleryitem())
            .then((response) => {
                response.data.data.map((e) => {
                    setData((obj) => {
                        return [...obj,
                        {
                            img: e.image,
                            title: e.title,
                            author: e.sub_title,
                            description: e.description,
                            p: <p><span>{e.created_at}</span></p>,
                            h5: e.heading,
                            content: e.description
                        }
                        ]
                    })

                })

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // console.log(data)


    return (
        <div>
            <PosterOne img={aboutbanner} title={'Gallery'} />


            <GallerySection itemData={data} />
        </div>
    )
}

export default Gallery;






export const GallerySection = ({ itemData, icon, icon3, icon2 }) => {

    const [videoActive, setVideoActive] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    const callbackHandler = (e) => {
        setVideoActive(false)
    }

    return <>
     

        <div className='container '>
            <div className='gallery-body'>
                <div className="row">
                    {
                        itemData?.map((res, key) => {
                            return <div className='col-md-4 col-sm-4 col-xs-12 ' key={key}>
                                <div className="galleryCard">
                                    <div className="gallery-box">
                                        <img src={res.img} alt=""  onClick={() => openLightbox(key)} // Open lightbox on image click
                                            style={{ cursor: 'pointer' }}/>
                                        {/* <div class="overlay-style-one">
                                            <div class="content">
                                                <div className='text-center'>
                                                    <h3>{res.title}</h3>
                                                    <div className='cmd'>
                                                        <a href="https://www.youtube.com/@dr.siamalmobarak_cardio">{icon2}</a>

                                                        {icon ? <h3 onClick={() => {
                                                            setVideoActive(res.imgvideo);
                                                        }}>
                                                            {icon}
                                                        </h3> : <span />}
                                                        {icon3}


                                                    </div>


                                                    <p>{res.author}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='blog-content py-3'>
                                        {/* {res.p} */}
                                        <h5>{res.h5}</h5>
                                        <p>{res.content}</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
            {lightboxOpen && (
                <Lightbox
                    mainSrc={itemData[photoIndex].img}
                    nextSrc={itemData[(photoIndex + 1) % itemData.length].img}
                    prevSrc={itemData[(photoIndex + itemData.length - 1) % itemData.length].img}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + itemData.length - 1) % itemData.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % itemData.length)}
                />
            )}
        </div>


        {
            videoActive ? <VideoPlayerShowCase callback={callbackHandler} video={videoActive} /> : false
        }
    </>
}


export const GalleryGrid = ({ itemData, icon, icon3, icon2 }) => {

    const [videoActive, setVideoActive] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    const callbackHandler = (e) => {
        setVideoActive(false)
    }

    return <>
     

        <div className='container '>
            <div className='gallery-body'>
                <div className="row">
                    {
                        itemData?.map((res, key) => {
                            return <div className='col-md-4 col-sm-4 col-xs-12 ' key={key}>
                                <div className="galleryCard">
                                    <div className="gallery-box">
                                        <img src={res.img} alt=""  onClick={() => openLightbox(key)} // Open lightbox on image click
                                            style={{ cursor: 'pointer' }}/>
                                        <div class="overlay-style-one">
                                            <div class="content">
                                                <div className='text-center'>
                                                    <h3>{res.title}</h3>
                                                    <div className='cmd'>
                                                        <a href="https://www.youtube.com/@dr.siamalmobarak_cardio" target='blank'>{icon2}</a>

                                                        {icon ? <h3 onClick={() => {
                                                            setVideoActive(res.imgvideo);
                                                        }}>
                                                            {icon}
                                                        </h3> : <span />}
                                                        {/* {icon3} */}


                                                    </div>


                                                    <p>{res.author}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='blog-content py-3'>
                                        {res.p}
                                        <h5>{res.h5}</h5>
                                        <p>{res.content}</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
            {lightboxOpen && (
                <Lightbox
                    mainSrc={itemData[photoIndex].img}
                    nextSrc={itemData[(photoIndex + 1) % itemData.length].img}
                    prevSrc={itemData[(photoIndex + itemData.length - 1) % itemData.length].img}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + itemData.length - 1) % itemData.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % itemData.length)}
                />
            )}
        </div>


        {
            videoActive ? <VideoPlayerShowCase callback={callbackHandler} video={videoActive} /> : false
        }
    </>
}


export const VideoGrid = ({ itemData, icon, icon3, icon2 }) => {

    const [videoActive, setVideoActive] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    const callbackHandler = (e) => {
        setVideoActive(false)
    }

    return <>
     

        <div className='container '>
            <div className='gallery-body'>
                <div className="row">
                    {
                        itemData?.map((res, key) => {
                            return <div className='col-md-4 col-sm-4 col-xs-12 ' key={key}>
                                <div className="galleryCard">
                                    <div className="gallery-box">
                                        <img src={res.img} alt=""   // Open lightbox on image click
                                            style={{ cursor: 'pointer' }}/>
                                        <div class="overlay-style-one">
                                            <div class="content">
                                                <div className='text-center'>
                                                    <h3>{res.title}</h3>
                                                    <div className='cmd'>
                                                        <a href="https://www.youtube.com/@dr.siamalmobarak_cardio" target='blank'>{icon2}</a>

                                                        {icon ? <h3 onClick={() => {
                                                            setVideoActive(res.imgvideo);
                                                        }}>
                                                            {icon}
                                                        </h3> : <span />}
                                                        {/* {icon3} */}


                                                    </div>


                                                    <p>{res.author}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='blog-content py-3'>
                                        {res.p}
                                        <h5>{res.h5}</h5>
                                        <p>{res.content}</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
            {lightboxOpen && (
                <Lightbox
                    mainSrc={itemData[photoIndex].img}
                    nextSrc={itemData[(photoIndex + 1) % itemData.length].img}
                    prevSrc={itemData[(photoIndex + itemData.length - 1) % itemData.length].img}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + itemData.length - 1) % itemData.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % itemData.length)}
                />
            )}
        </div>


        {
            videoActive ? <VideoPlayerShowCase callback={callbackHandler} video={videoActive} /> : false
        }
    </>
}
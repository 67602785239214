import React, { useEffect, useState } from 'react'
import PosterOne from '../share/component.jsx/posterone';
import aboutbanner from '../assets/aboutbanner.jpg';
import research1 from '../assets/research1.jpg';
import research2 from '../assets/research2.jpg';
import research3 from '../assets/research3.jpg';
import SearchIcon from '@mui/icons-material/Search';
import blog1 from '../assets/blog1.jpg';

import { News } from '.';
import axios from 'axios';
import { getClinicalLearningelement } from '../../Services/constant';
import { Link } from 'react-router-dom';



const research_data_list = [
  {
    img: research1,
    span: 'ONCOLOGY',
    h3: 'Catalysis Processes',
    p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
  },
  {
    img: research2,
    span: 'PATHOLOGY',
    h3: 'Organic Synthesis',
    p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
  },
  {
    img: research3,
    span: 'BIOSAFETY, INCUBATOR',
    h3: 'Hemoglobin Test',
    p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
  },
  {
    img: research3,
    span: 'HEMOGLOBIN',
    h3: 'Surface Cleaning',
    p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
  },
  {
    img: research1,
    span: 'ONCOLOGY',
    h3: 'Hemoglobin Test',
    p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
  },
  {
    img: research2,
    span: 'INCUBATOR',
    h3: 'Polymer Science',
    p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
  },
]


const newsdata = [
  {
    grid: 'col-md-4',
    img: research1,
    lbbutton: 'ONCOLOGY',
    p: <p><span>April 21, 2017</span></p>,
    h5: 'Catalysis Processes',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-4',
    img: research2,
    lbbutton: 'PATHOLOGY',
    p: <p><span>April 21, 2017</span></p>,
    h5: 'Organic Synthesis',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-4',
    img: research3,
    lbbutton: 'INCUBATOR',
    p: <p><span>April 21, 2017</span></p>,
    h5: 'Surface Cleaning',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-4',
    img: research2,
    lbbutton: 'ONCOLOGY',
    p: <p><span>April 21, 2017</span></p>,
    h5: 'Catalysis Processes',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-4',
    img: research3,
    lbbutton: 'PATHOLOGY',
    p: <p><span>April 21, 2017</span></p>,
    h5: 'Organic Synthesis',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-4',
    img: research1,
    lbbutton: 'INCUBATOR',
    p: <p><span>April 21, 2017</span></p>,
    h5: 'Surface Cleaning',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },



]
const Medical_product = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(getClinicalLearningelement())
      .then((response) => {
        // Filter data based on type
        const filteredData = response.data.data.filter(e => e.type === '3' );
  
        // Format filtered data
        const formattedData = filteredData.map((e) => ({
          grid: 'col-md-4',
          img: e.image,
          lbbutton: e.title,
          p: <p><span>{e.created_at}</span><span>10 Comments</span></p>,
          h5: e.heading,
          content: e.description,
          id: e.id // Add ID to the data
        }));
  
        setData(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  
  


  return (
    <>
      <PosterOne img={aboutbanner} title={'Others'} />
      {/* <Clinical_knowledge_list data ={research_data_list} /> */}
      {/* <div className="container my-5">
        <News data={ newsdata} />
      </div> */}
       <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row py-3">
              {data && data.map(res => {
                return <div key={res.id} className={res.grid}>
                <Link to={`/otherdetails/${res.id}`}>
               
                  <div className="blog-box">
                    <div className='blog-img'>
                      <img src={res.img} alt="" />
                      <div className="blog-label">
                        {res.icon}
                        <div className='blog-lb-btn'>
                          {res.lbbutton}
                        </div>
                      </div>
                    </div>
                    <div className='blog-content'>
                      {res.p}
                      <h5>{res.h5}</h5>
                     
                    </div>
                  </div>
                  </Link>
               
                </div>
              })}
            </div>
          </div>
         
        </div>
      </div>
    </>
  )
}
export default Medical_product
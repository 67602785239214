import { Box, Button, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SignIn from '../components/loginpage'
import axios from 'axios'
import { getblogelement, getgalleryitem, getvideoelement } from '../../Services/constant'

export const Dashboard = () => {
    const [galleryItem, setGalleryItem] = useState(0)
    const [videoItem, setVideoItem] = useState(0)
    const [blogItem, setBlogItem] = useState(0)
    useEffect(() => {       
        axios.get(getgalleryitem())
            .then((response) => {
                setGalleryItem(response.data.data.length)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        axios.get(getvideoelement())
            .then((response) => {
                setVideoItem(response.data.data.length)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        axios.get(getblogelement())
            .then((response) => {
                setBlogItem(response.data.data.length)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });






    }, [])



    return (
        <div className=''>
            <form style={{ boxShadow: 'none', border: 'none' }}>
                <h1 className='admin-header'>Admin Dashboard</h1>
                <div className="row">
                    <div className="col-md-3">
                        <div className='admin-dash-card'>
                            <h1 className='mb-2'>Gallery</h1>
                            <h2>{galleryItem}</h2>
                        </div>

                    </div>
                    <div className="col-md-3">
                        <div className='admin-dash-card'>

                            <h1 className='mb-2'>Video</h1>
                            <h2>{videoItem}</h2>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='admin-dash-card'>

                            <h1 className='mb-2'>Blogs</h1>
                            <h2>{blogItem}</h2>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='admin-dash-card'>

                            <h1 className='mb-2'>Product</h1>
                            <h2>{videoItem}</h2>
                        </div>
                    </div>
                </div>
                {/* <div className="cmd ">
                    <div></div>
                    <Button variant="contained">Submit</Button>
                </div> */}
            </form >

        </div >
    )
}

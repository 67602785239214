import React, { useEffect, useState } from 'react'
import aboutbanner from '../assets/aboutbanner.jpg';
import PosterOne from '../share/component.jsx/posterone';
import services1 from '../assets/services1.jpg';
import services2 from '../assets/services2.jpg';
import services3 from '../assets/services3.jpg';
import services4 from '../assets/services4.jpg'; 
import banner1 from '../assets/banner/1.jpeg';
import banner2 from '../assets/banner/2.jpeg';
import banner3 from '../assets/banner/3.jpeg';
import banner4 from '../assets/banner/4.jpeg';

import Diversity1Icon from '@mui/icons-material/Diversity1';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { Consultation, OurServices } from '.';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import img1 from '../assets/skill/1.png'
import img2 from '../assets/skill/2.png'
import img3 from '../assets/skill/3.png'
import img4 from '../assets/skill/4.png'
import img5 from '../assets/skill/5.png'
import img6 from '../assets/skill/6.png'
import img7 from '../assets/skill/7.png'
import img8 from '../assets/skill/8.png'

import CLINICAL from '../assets/icon/CLINICAL CONSULTATION.png';
import INTERVENTIONAL from '../assets/icon/INTERVENTIONAL CARDIOLOGY.png';
import CLINICALR from '../assets/icon/CLINICAL RESEARCH.png';
import MEDICAL from '../assets/icon/MEDICAL ACADEMIC CONSULTATION.png';

import icon1 from '../assets/flaticon/1.png'
import icon2 from '../assets/flaticon/2..png'
import icon3 from '../assets/flaticon/3..png'
import icon4 from '../assets/flaticon/4..png'

import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const Services = () => {
    return (
        <>
            <PosterOne img={aboutbanner} title={'Consultations'} />
          <OurServices/>
          
            <ServicesCategery />

        </>
    )
}

export default Services;



const categery_data_list = [
    {
        img: services1,
        icon: Diversity1Icon,
        title: 'For your child whitest teeths',
        head: 'CLINICAL ULTRASOUND TRAINING',
        pera: 'Praesent convallis tortor et enim laoreet, vel consectetur purus latoque penatibus et dis parturient.'
    },

    {
        img: services2,
        icon: Diversity1Icon,
        title: 'For your child whitest teeths',
        head: 'CARDIOLOGIST',
        pera: 'Praesent convallis tortor et enim laoreet, vel consectetur purus latoque penatibus et dis parturient.'
    },

    {
        img: services3,
        icon: Diversity1Icon,
        title: 'For your child whitest teeths',
        head: 'CHIROPRACTOR',
        pera: 'Praesent convallis tortor et enim laoreet, vel consectetur purus latoque penatibus et dis parturient.'
    },

    {
        img: services4,
        icon: Diversity1Icon,
        title: 'For your child whitest teeths',
        head: 'PEDIATRICIAN',
        pera: 'Praesent convallis tortor et enim laoreet, vel consectetur purus latoque penatibus et dis parturient.'
    },
]

const more_services_data = [
    {
        img: services4,
        span: ' What we do',
        h3: 'WE CARE',
        p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu hendrerit ex. Quisque imperdiet vel odio nec scelerisque."
    },
    {
        img: services2,
        span: ' What we do',
        h3: 'MEDICAL ADVICES ',
        p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu hendrerit ex. Quisque imperdiet vel odio nec scelerisque."
    },
    {
        img: services3,
        span: ' What we do',
        h3: 'MEDICAL SERVICES',
        p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu hendrerit ex. Quisque imperdiet vel odio nec scelerisque."
    },
    {
        img: services4,
        span: ' What we do',
        h3: 'PROFESSIONAL',
        p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu hendrerit ex. Quisque imperdiet vel odio nec scelerisque."
    },
]

export const ServicesCategery = () => {
    return <>

        <div className='successfull-experience'>
            <div className="successfull-body container">
                <div>
                    <i>Interesting Facts</i>
                    <h3>SUCESSFULL EXPERINCE</h3>
                </div>
                <div className="row my-4">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="succ-experi-box">
                            <div className="count">
                                <h1>01</h1>
                            </div>
                            <img src={img1} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="CLINICALR" />
                            <h2>MULTIPLE</h2>
                            <p>CLINICAL & HEALTHCARE CONSULTATION</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="succ-experi-box">
                            <div className="count">
                                <h1>02</h1>
                            </div>
                            <img src={img2} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />

                            <h2>500+</h2>
                            <p>CORONARY ANGIOGRAPHY (CAG) & RENAL ANGIOGRAPHY</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="succ-experi-box">
                            <div className="count">
                                <h1>03</h1>
                            </div>
                            <img src={img3} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="" precutaneous />
                            <h2>100+</h2>
                            <p>PERCUTANEOUS CORONARY INTERVENTION (PCI) SURGERIES</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="succ-experi-box">
                            <div className="count">
                                <h1>04</h1>
                            </div>
                            <img src={img4} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />

                            <h2>20+</h2>
                            <p>CORONARY TOTAL OCCULATION (CTO)</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="succ-experi-box">
                            <div className="count">
                                <h1>05</h1>
                            </div>
                            <img src={img5} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="pacemaker" />
                            <h2>10+</h2>
                            <p>PACEMAKER IMPLANTATION SURGERIES</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="succ-experi-box">
                            <div className="count">
                                <h1>06</h1>
                            </div>
                            <img src={img6} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="ballon" />
                            <h2>5+</h2>
                            <p>BALLOON VALVULOPLASTY (PVMV) SURGERIES</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="succ-experi-box">
                            <div className="count">
                                <h1>07</h1>
                            </div>
                            <img src={img7} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="clinical" />

                            <h2>10+</h2>
                            <p>CLINICAL<br /> PUBLICATION</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="succ-experi-box">
                            <div className="count">
                                <h1>08</h1>
                            </div>
                            <img src={img8} style={{ width: "85px", height: "85px", display: "inline-block" }} alt="" />
                            <h2>MULTIPLE</h2>
                            <p>MEDICAL WRITING & ACADEMIC CONSULTATION</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* <div className="container my-5">
            <div data-aos="fade-right">
                <i >Learn more</i>
                <h3>MORE SERVICES</h3>
            </div>
            <div className="row">
                {
                    categery_data_list.map((res, key) => {
                        return
                        <div className="col-md-6 col-lg-3 col-12">
                            <div className="services_cate_box">
                                <div className="cate_img_box">
                                    <img src={res.img} alt="" />
                                    <div className="iconBox">
                                        <div className="iconBox-icon">
                                            {<res.icon />}

                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <h6>{res.title}</h6>
                                    <h3>{res.head}</h3>
                                    <li>{res.pera}</li>
                                </div>
                            </div>
                        </div>
                    })
                }

            </div>

        </div> */}

        {/* more servie */}
        {/* <div className="container my-5">
            <div data-aos="fade-right">
                <i >Learn more</i>
                <h3>MORE SERVICES</h3>
            </div>
            <div className="row">
                <div className="col-md-6 col-lg-3 col-12">
                    <div className="services_cate_box">
                        <div className="cate_img_box">
                            <img src={banner1} alt="" />
                            <div className="iconBox">
                                <div className="iconBox-icon">
                                    <img src={icon1} style={{height:"45px",width:"45px"}} alt="i" />

                                </div>
                            </div>
                        </div>
                        <div className="content">

                            <h3>CLINICAL ULTRASOUND TRAINING</h3>
                            <li> 		Image Optimization</li>
                            <li> 		Interpretation Of Ultrasound</li>
                            <li>		Ultrasound Instrumentation & Equipment Positioning</li>
                            <li>	Ultrasound Guided Procedures  </li>
                            <li>		Patient Positioning & Scanning Techniques  </li>
                            <li> 		Safety & Quality Assurance in Ultrasound Imaging</li>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-12">
                    <div className="services_cate_box">
                        <div className="cate_img_box">
                        <img src={banner2} alt="DENTAL CONSULTATION" />

                            <div className="iconBox">
                                <div className="iconBox-icon">
                                <img src={icon2} style={{height:"55px",width:"55px"}} alt="i" />
                               

                                </div>
                            </div>
                        </div>
                        <div className="content">

                            <h3>DENTAL CONSULTATION</h3>
                            <li> 		General Dental Management</li>
                            <li> 	Dental Pain Management</li>
                            <li> 	Dental Public Health Awareness</li>
                            <li> 	Oral Disease Consultation</li>
                            <li>		Oral Hygiene Management  </li>
                            <li> 	Periodontal Disease Management</li>
                            <li> 	Aesthetic Dentistry Consultation</li>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-12">
                    <div className="services_cate_box">
                        <div className="cate_img_box">
                        <img src={banner3} alt="FOOD & NUTRITION" />
                           
                            <div className="iconBox">
                                <div className="iconBox-icon">
                                <img src={icon3} style={{height:"55px",width:"55px"}} alt="i" />


                                </div>
                            </div>
                        </div>
                        <div className="content">

                            <h3>FOOD & NUTRITION</h3>
                            <li>	Develop A Personalized Nutrition Plan</li>
                            <li> 		Personalized Dietary Habits & Health Goals </li>
                            <li>		Provide Healthy Metabolism Techniques</li>
                            <li> 		Guidance On Making Sustainable Lifestyle </li>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-12">
                    <div className="services_cate_box">
                        <div className="cate_img_box">
                        <img src={banner4} alt="OTHERS" />

                            <div className="iconBox">
                                <div className="iconBox-icon">
                                <img src={icon4} style={{height:"55px",width:"55px"}} alt="i" />


                                </div>
                            </div>
                        </div>
                        <div className="content">

                            <h3>OTHERS</h3>
                            <li> 		Medical Care Follow-up </li>
                            <li> 	Non-urgent Medical Care Consultation</li>
                        </div>
                    </div>
                </div>
            </div>

        </div> */}
        

        {/* <div className="container my-5 ">
            <div className='my-5' data-aos="fade-right">
                <i >Learn more</i>
                <h3>MORE SERVICES</h3>
            </div>
            <div className="row ">
                {
                    more_services_data.map((res, key) => {
                        return <div className="col-md-6">
                            <div className="more_services_box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div><img src={res.img} alt="" /></div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div>
                                            <span>{res.span}</span>
                                            <h3>{res.h3}</h3>
                                            <p>{res.p}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    })
                }

            </div>
        </div> */}
          <MoreServices/>

        <Consultation/>
        <br/>  <br/>  <br/>  <br/>  <br/>
    </>
}


export const MoreServices = () => {


    const [slidesPerView, setSlidesPerView] = useState(4); // Default slides per view
  
    useEffect(() => {
      // Update slidesPerView based on screen width
      const handleResize = () => {
        if (window.innerWidth <= 767) {
          setSlidesPerView(1); // Set slides per view to 1 for mobile screens
        } else {
          setSlidesPerView(4); // Set default slides per view for larger screens
        }
      };
  
      // Initial setup
      handleResize();
  
      // Listen for window resize events
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return <>
      <div className="our-services ">
        <div className='container'>
          <div data-aos="fade-right">
          <i >Learn more</i>
                <h3>MORE CONSULTATIONS</h3>
                <h4> The associates of DR. SIAM AL MOBARAK can offer more specialized consultations on:</h4>
          </div>
  
          <div className='our-services-body'>
            <div className='py-4'>
  
  
              <Swiper
                className="mySwiper"
                pagination={{ clickable: true }}
                spaceBetween={20}
                slidesPerView={slidesPerView}
                centeredSlides={false}
                autoplay={{ delay: 3000000000000, disableOnInteraction: false }}
              >
                <SwiperSlide>
                  <img src={banner1} alt="" />
                  <div className='services-context-pera'>
  
                    <div className='services-body'>
                  
                    <img src={icon1} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="CLINICAL" />
                           
                      <h5>CLINICAL ULTRASOUND TRAINING</h5>
                      <p style={{ fontSize: '11px', color: '#000000' }}>                      
                         <li> 	●	Image Optimization</li>
                            <li> ●		Interpretation Of Ultrasound</li>
                            <li>●		Ultrasound Instrumentation & Equipment Positioning</li>
                            <li>●	Ultrasound Guided Procedures  </li>
                            <li>●		Patient Positioning & Scanning Techniques  </li>
                            <li> ●		Safety & Quality Assurance in Ultrasound Imaging</li>
                      </p>
  
                    </div>
                  </div>
  
                  <div className='services-context'>
                    <div className='services-body'>
                    <img src={icon1} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="CLINICAL" />
  
  
                      <h5>CLINICAL ULTRASOUND TRAINING</h5>
                    </div>
                  </div>
                </SwiperSlide>
  
                <SwiperSlide>
                  <img src={banner2} />
                  <div className='services-context-pera'>
  
                    <div className='services-body'>
                      
                      <img src={icon2} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="INTERVENTIONAL" />
                      
                           
                      <h5>DENTAL CONSULTATION</h5>
                      <p style={{ fontSize: '11px', color: '#000000' }}>
                      <li> 	●	General Dental Management</li>
                            <li>● 	Dental Pain Management</li>
                            <li> ●	Dental Public Health Awareness</li>
                            <li> ●	Oral Disease Consultation</li>
                            <li>	●	Oral Hygiene Management  </li>
                            <li> ●	Periodontal Disease Management</li>
                            <li> ●	Aesthetic Dentistry Consultation</li>
                       
                      </p>
  
                    </div>
                  </div>
                  <div className='services-context'>
                    <div className='services-body'>
                    <img src={icon2} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="INTERVENTIONAL" />
  
                      <h5>DENTAL CONSULTATION </h5>
                    </div>
                  </div>
                </SwiperSlide>
  
                <SwiperSlide>
                  <img src={banner3} alt="" />
                  <div className='services-context-pera'>
  
                    <div className='services-body'>
                    
                      <img src={icon3} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="CLINICALR" />
                    
                           
                      <h5>FOOD & NUTRITION </h5>
                      <p style={{ fontSize: '11px', color: '#000000' }}> 
                      <li> ● Clinical Research Projects potentiality Consultation </li>
                       
                        <li>	● Develop A Personalized Nutrition Plan</li>
                            <li> ●		Personalized Dietary Habits & Health Goals </li>
                            <li>	●	Provide Healthy Metabolism Techniques</li>
                            <li> ●		Guidance On Making Sustainable Lifestyle </li>
                      </p>
                    </div>
                  </div>
                  <div className='services-context'>
                    <div className='services-body'>
                    <img src={icon3} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="CLINICALR" />
  
  
                      <h5>FOOD & NUTRITION</h5>
                    </div>
                  </div>
                </SwiperSlide>
  
  
                <SwiperSlide>
                  <img src={banner4} />
                  <div className='services-context-pera'>
  
                    <div className='services-body'>
                    <img src={icon4} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="MEDICAL" />

                           
  
                      <h5>OTHERS</h5>
                      <p style={{ fontSize: '11px', color: '#000000' }}> 
                      <li> 	●	Medical Care Follow-up </li>
                            <li> ●	Non-urgent Medical Care Consultation</li>
                     
                     
                      </p>
                    </div>
                  </div>
                  <div className='services-context'>
                    <div className='services-body'>
                    <img src={icon4} style={{ width: "48px",height:"48px",display:"inline-block" }} alt="MEDICAL" />
  
  
                      <h5>OTHERS</h5>
                    </div>
                  </div>
                </SwiperSlide>
  
                {/* <SwiperSlide>
                  <img src={services1} alt="" />
                  <div className='services-context-pera'>
  
                    <div className='services-body'>
                      <FitnessCenterIcon />
  
                      <h5>Excercise Program</h5>
                      <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                      <button className='btn-two'>Read More</button>
                    </div>
                  </div>
                  <div className='services-context'>
                    <div className='services-body'>
                      <FitnessCenterIcon />
  
                      <h5>Balance Body Mind</h5>
                    </div>
                  </div>
                </SwiperSlide>
  
  
                <SwiperSlide>
                  <img src={services2} />
                  <div className='services-context-pera'>
  
                    <div className='services-body'>
                      <FitnessCenterIcon />
  
                      <h5>Excercise Program</h5>
                      <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                      <button className='btn-two'>Read More</button>
                    </div>
                  </div>
                  <div className='services-context'>
                    <div className='services-body'>
                      <HealthAndSafetyIcon />
                      <h5>Balance Body Mind</h5>
                    </div>
                  </div>
                </SwiperSlide> */}
  
              </Swiper>
  
  
  
            </div>
          </div>
        </div>
      </div>
    </>
  }

export const OurServicesWithoutGrid = () => {


    return <>
        <div className="our-services ">
            <div className='container'>
                <div data-aos="fade-right">
                    <i >What I Do</i>
                    <h3>Consultation</h3>
                </div>

                <div className='our-services-body'>
                    <div className='py-4'>


                        <div className="row">
                            <div className='col-md-3 mt-4 '>
                                <div className="swiper-slide">
                                    <img src={services1} alt="" />
                                    <div className='services-context-pera'>

                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                            <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                                            <button className='btn-two'>Read More</button>
                                        </div>
                                    </div>

                                    <div className='services-context'>
                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 mt-4'>
                                <div className="swiper-slide">
                                    <img src={services2} />
                                    <div className='services-context-pera'>

                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                            <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                                            <button className='btn-two'>Read More</button>
                                        </div>
                                    </div>
                                    <div className='services-context'>
                                        <div className='services-body'>
                                            <HealthAndSafetyIcon />

                                            <h5>Balance Body Mind</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 mt-4'>
                                <div className="swiper-slide">
                                    <img src={services3} alt="" />
                                    <div className='services-context-pera'>

                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                            <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                                            <button className='btn-two'>Read More</button>
                                        </div>
                                    </div>
                                    <div className='services-context'>
                                        <div className='services-body'>
                                            <DirectionsRunIcon />

                                            <h5>Physical Activity</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='col-md-3 mt-4'>
                                <div className="swiper-slide">
                                    <img src={services4} />
                                    <div className='services-context-pera'>

                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                            <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                                            <button className='btn-two'>Read More</button>
                                        </div>
                                    </div>
                                    <div className='services-context'>
                                        <div className='services-body'>
                                            <MonitorHeartIcon />

                                            <h5>Support & Motivation</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 mt-4'>
                                <div className="swiper-slide">
                                    <img src={services1} alt="" />
                                    <div className='services-context-pera'>

                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                            <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                                            <button className='btn-two'>Read More</button>
                                        </div>
                                    </div>
                                    <div className='services-context'>
                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Balance Body Mind</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='col-md-3 mt-4'>
                                <div className="swiper-slide">
                                    <img src={services2} />
                                    <div className='services-context-pera'>

                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                            <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                                            <button className='btn-two'>Read More</button>
                                        </div>
                                    </div>
                                    <div className='services-context'>
                                        <div className='services-body'>
                                            <HealthAndSafetyIcon />
                                            <h5>Balance Body Mind</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 mt-4 '>
                                <div className="swiper-slide">
                                    <img src={services1} alt="" />
                                    <div className='services-context-pera'>

                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                            <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                                            <button className='btn-two'>Read More</button>
                                        </div>
                                    </div>

                                    <div className='services-context'>
                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 mt-4'>
                                <div className="swiper-slide">
                                    <img src={services2} />
                                    <div className='services-context-pera'>

                                        <div className='services-body'>
                                            <FitnessCenterIcon />

                                            <h5>Excercise Program</h5>
                                            <p>Finding a workout is as easy as scrolling down, picking out the session that matches.</p>
                                            <button className='btn-two'>Read More</button>
                                        </div>
                                    </div>
                                    <div className='services-context'>
                                        <div className='services-body'>
                                            <HealthAndSafetyIcon />

                                            <h5>Balance Body Mind</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>
    </>
}
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Stack } from '@mui/material';

export default function SnackbarAlert({ active, type, message }) {


    useEffect(() => {
        if (active) {
            setOpen(true)
        }
    }, [active])

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            {/* <Button color="success" size="small" onClick={handleClose}>
                UNDO
            </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div className='snackbarAlert'>

            <Stack >
                <Snackbar
                    sx={{ width: '100%', }}
                    open={open}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={handleClose}
                // message="message done"
                >
                    <Alert severity={type ? type : 'success'} sx={{ width: '98%' }} action={action}>{message}</Alert>
                </Snackbar>
            </Stack>
        </div>
    );
}



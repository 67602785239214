import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './mycomponent/share/header'
import Footer from './mycomponent/share/footer'

const Layout = () => {
    return (
        <>
                <Header />
            <div style={{ minHeight: "75vh" }}>
                <Outlet>

                </Outlet>
            </div>
            <Footer />
        </>
    )
}

export default Layout
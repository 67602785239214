import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function BasicTable({ rows, columns, ActionCell }) {
    return (
        <TableContainer className='mt-5' component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            columns.map((res, index) => {
                                return <TableCell key={index}>{res.label}</TableCell>
                            })
                        }


                        {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {

                                columns.map((column) => {
                                    return (
                                        row[column.id] ?
                                            <TableCell component="th" scope="row">
                                                {row[column.id]}
                                            </TableCell> :
                                            <TableCell component="th" scope="row">
                                                {ActionCell({ row })}
                                            </TableCell>
                                    )


                                })

                            }


                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
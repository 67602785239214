import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "../layout";
import Home from "../mycomponent/pages";
import { about, admin, adminblog,blogdetails, adminclinical, admingallery, adminmedical, adminvideo, blog, clinical, contact, dashboard, gallery, home, product, research, services, videos, adminreasearchform, HealthCare, healthcare, researchcard, clinicaldetails, healthcaredetails, otherdetails, researchdetails, adminblognew, contactreport } from "./constant";
import About from "../mycomponent/pages/about";
import Gallery from "../mycomponent/pages/gallery";
import Contact from "../mycomponent/pages/contact";
import Blog from "../mycomponent/pages/blog";
import Video from "../mycomponent/pages/video";
import Services from "../mycomponent/pages/services";
import Research from "../mycomponent/pages/research";
import BlogDetails from "../mycomponent/pages/blogdetails";
import Clinical_knowledge from "../mycomponent/pages/clinical_knowledge";
import { useEffect } from "react";
import Medical_product from "../mycomponent/pages/medical_product";
import Admin_layout from "../admin/admin_layout";
import { Dashboard } from "../admin/pages";
import AdminBlog from "../admin/pages/blog";
import AdminGallery from "../admin/pages/gallery";
import AdminMedicalProduct from "../admin/pages/medical_prod";
import AdminVideo from "../admin/pages/video";
import SignIn from "../admin/components/loginpage";
import AdminClinicalLearning from "../admin/pages/clinicalLearning";
import ResearchFaq from "../admin/pages/ResearchPublication";
import PublicationForm from "../admin/pages/PublicationForm";
import Healthcare from "../mycomponent/pages/HealthCare";
import ResearchCard from "../mycomponent/pages/ResearchCard";
import OtherDetails from "../mycomponent/pages/otherdetails";
import ClinicalDetails from "../mycomponent/pages/Clinicaldetails";
import HealthDetails from "../mycomponent/pages/HealthDetails";
import ResearchDetails from "../mycomponent/pages/ResearchDetails";
import BlogNew from "../admin/pages/blognew";
import AdminClinicalLearningZone from "../admin/pages/LearningZone";
import ContactReport from "../admin/pages/ContactReport";



const RouterConfrigration = () => {

    return (
        <>
            <ScrollToTop /> {/* Scroll to top on every route change */}

            <Routes>

                {/* <Route path={nopage} element={<NoPage />} />
            <Route path={login} element={<Login />} />
            <Route path={pllogin} element={<PlLogin />} /> */}

                {/* register by mail url path */}
                {/* <Route path={register_InvitePath} element={<Register />} />
            <Route path={register} element={<Register />} /> */}

                {/* forgot by mail url path */}
                {/* <Route path={forgotPath} element={<Forgot />} />
            <Route path={forgot} element={<Forgot />} /> */}

                <Route path={home} element={<Layout />}>
                    <Route index path={home} element={<Home />} />
                    <Route path={about} element={<About />} />
                    <Route path={blog} element={<Blog />} />
                    <Route path={gallery} element={<Gallery />} />
                    <Route path={contact} element={<Contact />} />
                    <Route path={videos} element={<Video />} />
                    <Route path={services} element={<Services />} />
                    <Route path={research} element={<Research />} />
                    <Route path={clinical} element={<Clinical_knowledge />} />
                    <Route path={product} element={<Medical_product />} />
                    <Route path={healthcare} element={<Healthcare />} />
                    <Route path={researchcard} element={<ResearchCard />} />
                    {/* <Route path={blogdetails} element={<BlogDetails />} /> */}
                    <Route path={`${blogdetails}/:id`} element={<BlogDetails />} />
                    <Route path={`${researchdetails}/:id`} element={<ResearchDetails />} />
                    <Route path={`${clinicaldetails}/:id`} element={<ClinicalDetails />} />
                    <Route path={`${healthcaredetails}/:id`} element={<HealthDetails />} />
                    <Route path={`${otherdetails}/:id`} element={<OtherDetails />} />


                </Route>

                
                <Route path={admin} element={<SignIn />} />

                <Route path={admin} element={<Admin_layout />}>
                    <Route path={dashboard} element={<Dashboard />} />
                    <Route path={adminblog} element={<AdminBlog />} />
                    <Route path={adminblognew} element={<BlogNew />} />
                    <Route path={admingallery} element={<AdminGallery />} />
                    <Route path={adminmedical} element={<AdminMedicalProduct />} />
                    <Route path={adminvideo} element={<AdminVideo />} />
                    <Route path={adminreasearchform} element={<ResearchFaq />} />
                    <Route path={adminclinical} element={<AdminClinicalLearningZone />} />
                    <Route path={contactreport} element={<ContactReport />} />

                </Route>
            </Routes>
        </>
    )
}

export default RouterConfrigration;


export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null; // The component doesn't render anything
};


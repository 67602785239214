import React, { useEffect, useState } from 'react'
import PosterOne from '../share/component.jsx/posterone'
import blogbanner from '../assets/aboutbanner.jpg'
import ShareIcon from '@mui/icons-material/Share';
import blog1 from '../assets/blog1.jpg';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { News } from '.';
import { API_BASE_URL, API_ENDPOINTS, getblogelement } from '../../Services/constant';
import axios from 'axios';


const blogdata = [
  {
    grid: 'col-md-6',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great',
  },
  {
    grid: 'col-md-6',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-6',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-6',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },


  {
    grid: 'col-md-6',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },
  {
    grid: 'col-md-6',
    img: blog1,
    lbbutton: 'Fitness',
    p: <p><span>April 21, 2017</span><span>10 Comments</span></p>,
    h5: 'Stop Getting Annoyed If You Want Better Health',
    content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
  },



]

const Blog = () => {

  
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(getblogelement())
      .then((response) => {
        const formattedData = response.data.data.map((e) => ({
          grid: 'col-md-6',
          img: e.image,
          lbbutton: e.title,
          p: <p><span>{e.created_at}</span></p>,
          h5: e.heading,
          content: e.description,
          id: e.id // Add ID to the data
        }));
        setData(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);




  return (
    <>
      <PosterOne img={blogbanner} title={'Blogs'} />

      <div className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <div className="row py-3">
              {data && data.map(res => {
                return <div key={res.id} className={res.grid}>
                   <Link to={`/BlogDetails/${res.id}`}>
                  <div className="blog-box">
                    <div className='blog-img'>
                      <img src={res.img} alt="" />
                      <div className="blog-label">
                        {res.icon}
                        <div className='blog-lb-btn'>
                          {res.lbbutton}
                        </div>
                      </div>
                    </div>
                    <div className='blog-content'>
                      {res.p}
                      <h5>{res.h5}</h5>
                     
                    </div>
                  </div>
                  </Link>
                </div>
              })}
            </div>
          </div>
        
        </div>
      </div>
    </>
  )
}

export default Blog
import React, { useEffect, useState } from 'react'
import PosterOne from '../share/component.jsx/posterone';
import aboutbanner from '../assets/aboutbanner.jpg'
import videoimg1 from '../assets/video1.jpeg'
import videoimg2 from '../assets/video2.jpeg'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LinkIcon from '@mui/icons-material/Link';
import video1 from '../video/horse.mp4';
import CloseIcon from '@mui/icons-material/Close';
import services1 from '../assets/services1.jpg'
import services2 from '../assets/services2.jpg'
import services3 from '../assets/services3.jpg'
import services4 from '../assets/services4.jpg'
import { GalleryGrid, VideoGrid } from './gallery';


import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { getvideoelement } from '../../Services/constant';
import VideoComponent from './videotest';

// Initialize Swiper core with required modules
SwiperCore.use([Autoplay, Pagination]);

const itemData = [
    {
        img: services1,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        imgvideo: video1,
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'
    },
    {
        img: services2,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        imgvideo: video1,
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'

    },
    {
        img: services3,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        imgvideo: video1,
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'

    },
    {
        img: services4,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        imgvideo: video1,
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'

    },
    {
        img: services1,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        imgvideo: video1,
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'

    },
    {
        img: services2,
        title: 'Happy Young Family',
        author: 'Healthy Food',
        imgvideo: video1,
        p: <p><span>April 21, 2017</span></p>,
        h5: 'Stop Getting Annoyed If You Want Better Health',
        content: 'pleasure and praising pain was born I will give you a complete account of the system, and expound actual teachings great'


    },




];




const Video = () => {

    const [data, setData] = useState([]);


    useEffect(() => {
        // Combine the base URL and endpoint

        axios.get(getvideoelement())
            .then((response) => {
                response.data.data.map((e) => {
                    setData((obj) => {
                        return [...obj,
                        {
                            img: e.image,
                            title: e.title,
                            author: e.sub_title,
                            imgvideo: e.video,
                            p: <p><span>{e.date}</span></p>,
                            h5: e.heading,
                            content: e.description
                        }
                        ]
                    })

                })

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);



    return (
        <>
            <PosterOne img={aboutbanner} title={'Videos'} />
            <VideoPlaySection/>
           <br/>
            <div className='container py-6'>
        <div data-aos="fade-right">
          <i >My </i>
          <h3>Videos</h3>
        </div>
            <VideoGrid itemData={data} icon={<PlayArrowIcon />} icon2={<YouTubeIcon />} icon3={<LinkedInIcon style={{ fontSize: "40px" }} />} />
            </div>

            
        </>
    )
}

// export default Video;


export const VideoPlaySection = () => {

    const [videoActive, setVideoActive] = useState(false);

    const videoHandler = (e) => {
        setVideoActive(e)
    }

    const callbackHandler = (e) => {
        setVideoActive(e)
    }
   
    return <>
        <div className="video-container py-5 ">
            <div className="container">    
                <div className='text-center'>
                    <i>In-Depth Interview:</i>
                    <h3 className='mb-4'> Dr. Siam on Cardiovascular Health</h3>
                </div>
                <div>
                    <Swiper
                        className="mySwiper"
                        pagination={{ clickable: true }}
                        spaceBetween={20}
                        slidesPerView={1}
                        centeredSlides={false}
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                    >
                        <SwiperSlide>
                            <div className="video-box">
                                <img src={videoimg1} alt="" />
                                <div className="video-caption" onClick={() => videoHandler("https://projects.drsiamalmobarakcardio.com/public/videos/tv_interview.mp4")}>
                                    <h1>
                                        Dr. Siam on <br /> Cardiovascular Health
                                    </h1>
                                    <PlayCircleIcon />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="video-box">
                                <img src={videoimg2} alt="" />
                                <div className="video-caption" onClick={() => videoHandler("https://projects.drsiamalmobarakcardio.com/public/videos/cardiology_seminar.mp4")}>
                                    <h1>
                                        Dr. Siam on <br /> Cardiovascular Health
                                    </h1>
                                    <PlayCircleIcon />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            {
                videoActive ? <VideoPlayerShowCase callback={callbackHandler} video={videoActive} /> : false
            }


        </div>
    </>
}


export const VideoPlayerShowCase = ({ callback, video }) => {
    const handleInnerClick = (event) => {
        // Prevent the event from propagating to the outer div
        event.stopPropagation();
    };

    return <>
        <div className='videoPlayer-showCase' onClick={() => callback(false)}>
            <div className='videoPlayer-body' onClick={handleInnerClick}>
                <CloseIcon onClick={() => callback(false)} style={{ position: "absolute", right: "-30px", top: "0", color: "#fff", fontSize: "2rem" }} />

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
            <video
                controls
                style={{
                    width: '100%',
                    maxWidth: '600px',
                    height: 'auto',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>

            </div>


        </div>
    </>
}
export default Video;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { contactreport } from '../../Services/constant';

const ContactReport = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch data from the API
        axios.get(contactreport())
            .then((response) => {
                if (response.data.status === 200) {  // Ensure the status check is on number, not string
                    setContacts(response.data.data); // Directly set the 'data' array from response
                } else {
                    console.error('Error fetching data');
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('API Error:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-5">
            <h2>Contact Report</h2>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Consultation Type</th>
                        <th>Message</th>
                        <th>Image</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.length > 0 ? (
                        contacts.map((contact) => (
                            <tr key={contact?.id}>
                                <td>{contact?.id}</td>
                                <td>{contact?.name}</td>
                                <td>{contact?.phone}</td>
                                <td>{contact?.email}</td>
                                <td>{contact?.consultation_type || "N/A"}</td>
                                <td>{contact?.message}</td>
                                <td>
                                    <a href={contact?.image} target="_blank" rel="noopener noreferrer">
                                        View File
                                    </a>
                                </td>
                                <td>{new Date(contact.created_at).toLocaleString()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8" className="text-center">No contact data available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ContactReport;

import { Button, TextField } from '@mui/material'
import React from 'react'

const AdminMedicalProduct = () => {
  return (
    <div>
        <div className=''>
            <form>
                <h1>Admin Medical Product</h1>
                <div className="row">
                    <div className="col-md-4">
                        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                    </div>
                    <div className="col-md-4">
                        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                    </div>
                    <div className="col-md-4">
                        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                    </div>
                </div>
                <div className="cmd ">
                    <div></div>
                    <Button variant="contained">Submit</Button>
                </div>
            </form>

        </div>
    </div>
  )
}

export default AdminMedicalProduct
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deleteClinicalLearningelement, deletegalleryitem, getClinicalLearningelement, getgalleryitem, postClinicalLearningelement, postgalleryitem, researchpublication } from '../../Services/constant';
import axios from 'axios';
import SnackbarAlert from '../../mycomponent/share/component.jsx/snackbar';
import BasicTable from '../components/muiTable';
import DeleteIcon from '@mui/icons-material/Delete';



const ResearchFaq = () => {


    const [journal_name, setjournal_name] = useState(1)
    const [artical_heading, setartical_heading] = useState('');
    const [pmid, setpmid] = useState('');
    const [publication_year, setpublication_year] = useState('');
    const [link, setlink] = useState('');
    const [doi, setdoi] = useState(null);
    const [active, setActive] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('success')

    const [data, setData] = useState([])

    const columns = [
        {
            id: 'id',
            label: 'ID'
        },
        {
            id: 'heading',
            label: 'Heading'
        },
        {
            id: 'link',
            label: 'link'
        },

        {
            id: 'action',
            label: 'Action'
        }
    ]

    const fatch_data = () => {
        setData([])
        axios.get(getClinicalLearningelement())
            .then((response) => {

                response.data.data.map((e) => {
                    setData((obj) => {
                        return [...obj,
                        {
                            id: e.id,
                            heading: e.heading,
                            link: e.link
                            // img: e.image,
                            // lbbutton: e.pmid,
                            // p: <p><span>{e.created_at}</span><span>10 Comments</span></p>,
                            // h5: e.heading,
                            // content: e.link
                        }

                        ]
                    })

                })

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        fatch_data()
    }, [])

    useEffect(() => {
        setActive(false)
    }, [active])

    const formData = new FormData();
    formData.append('journal_name', journal_name);
    formData.append('artical_heading', artical_heading);
    formData.append('pmid', pmid);
    formData.append('sub_title', publication_year);
    formData.append('link', link);
    formData.append('doi', doi);
    formData.append('link', ['N/A']);
    formData.append('video', ['N/A']);

    const handlePostRequest = () => {
        if (!artical_heading || !journal_name || !publication_year || !link || !doi || !pmid) {
            setActive(true)
            setAlertType('error')
            setAlertMessage('Please fill all the credentials')
        }
        else {
            console.log("post data =>",formData)
            axios.post(researchpublication(), formData)
                .then((response) => {
                    setActive(true)
                    setAlertType('success')
                    setAlertMessage('Submit Successfully')

                    setartical_heading('')
                    setjournal_name('')
                    setpublication_year('')
                    setlink('')
                    setdoi('')
                    setpmid('')
                })
        }

    }

  

    const ActionCell = ({ row }) => {

        const deleteHandler = () => {
            axios.post(deleteClinicalLearningelement(), { id: row.id })
                .then(() => {
                    fatch_data()
                })
        }



        return <>
            <Tooltip>
                <IconButton onClick={deleteHandler}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    }

    return (
        <div>
            <div className=''>
                <SnackbarAlert active={active} type={alertType} message={alertMessage} />
                <form>
                    <h1>Admin Publications </h1>
                    <div className="row">
                        {/* <div className="col-md-4 mt-4">
                            <FormControl className='' sx={{ width: '100%' }}>
                                <InputLabel id={`label`}>{'select type'}</InputLabel>
                                <Select
                                    // className='select'
                                    labelId={`label`}
                                    id={`select`}
                                    // name={field.label}
                                    value={type}
                      
                                    label={'select type'}
                                    onChange={(e) =>
                                        setType(e.target.value)
                                    }
                                > 
                                    <MenuItem value={1} >Research and Publication</MenuItem>
                                  
                                </Select>
                            </FormControl>
                        </div> */}
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="Artical Heading" variant="outlined" value={artical_heading} onChange={(e) => { setartical_heading(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="journal name" variant="outlined" value={journal_name} onChange={(e) => { setjournal_name(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="publication year" variant="outlined" value={publication_year} onChange={(e) => { setpublication_year(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="link" variant="outlined" value={link} onChange={(e) => { setlink(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="doi" variant="outlined" value={doi} onChange={(e) => { setdoi(e.target.value); }} />
                        </div>
                        <div className="col-md-4 mt-4">
                            <TextField id="outlined-basic" label="pmid" variant="outlined" value={pmid} onChange={(e) => { setpmid(e.target.value); }} />
                        </div>

                     
                    </div>
                    <div className="cmd ">
                        <div></div>
                        <Button onClick={handlePostRequest} variant="contained">Submit</Button>
                    </div>
                </form>

                <BasicTable
                    rows={data}
                    columns={columns}
                    ActionCell={ActionCell}
                />

            </div>
        </div>
    )
}

export default ResearchFaq;
import React, { useState } from 'react'
import aboutbanner from '../assets/aboutbanner.jpg';
import research1 from '../assets/research1.jpg';
import research2 from '../assets/research2.jpg';
import research3 from '../assets/research3.jpg';
import PosterOne from '../share/component.jsx/posterone';
import { News } from '.';


const Research = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [faqs, setFaqs] = useState([
        {
            heading: 'Social network strategy as a promising intervention to better reach key populations for promoting HIV prevention: a systematic review and meta-analysis 2020',
            PublicationYear: '2020',
            Links: 'https://sti.bmj.com/content/96/7/485.full?ijkey=aJXZgyjJ1CbSH3L&keytype=ref&from=singlemessage',
            JournalName: " BMJ Journals ",
            DOI: '10.1136/sextrans-2019-054349',
            PMID: '32209639',
            open: false
        },
        {
            heading: 'Early outcomes of a rural-oriented physician education programme against rural physician shortages in Guangxi province: a prospective cohort study',
            PublicationYear: ' 2021 ',
            Links: 'https://bmjopen.bmj.com/content/11/9/e049274',
            JournalName: " BMJ Open ",
            DOI: 'N/A',
            PMID: 'N/A',
            open: false
        },
        {
            heading: 'META-ANALYSIS: A COMPARISON OF ASPIRIN AND RIVAROXABAN FOR PROPHYLAXIS OF VENOUS THROMBOEMBOLISM AFTER HIP OR KNEE ARTHROPLASTY',
            PublicationYear: '2021',
            Links: 'https://xianshiyoudaxuexuebao.com/detail.php?id=DOI:10.17605/OSF.IO/XG6H8 ',
            JournalName: " Journal of Xi'an Shiyou University, Natural Sciences Edition  ",
            DOI: '10.17605/OSF.IO/XG6H8',
            PMID: 'N/A.',
            open: false
        },
        {
            heading: 'An online survey and review about the awareness, coping style, and exercise behavior during the ‘‘COVID-19 pandemic situation’’ by implementing the cloudbased medical treatment technology system in China among the public',
            PublicationYear: '2021',
            Links: 'https://xianshiyoudaxuexuebao.com/detail.php?id=DOI:10.17605/OSF.IO/XG6H8 ',
            JournalName: " SCIENCE PROGRESS  ",
            DOI: '10.1177/00368504211000889',
            PMID: '33827338',
            open: false
        },
        {
            heading: 'The Review literature about Chondroblastoma with a case on Talus bone',
            PublicationYear: '2021',
            Links: 'http://www.hkorthopaedicjournal.com/HKJOR_202033_06.pdf '+!'https://www.hkorthopaedicjournal.com/Volume3issue3.html',
            JournalName: " Hong Kong Journal of Orthopaedic Research  ",
            DOI: '10.37515/ortho.8231.3306',
            PMID: 'N/A.',
            open: false
        },
        {
            heading: 'Clinical and strategic outcomes of metastatic synovial sarcoma on limb',
            PublicationYear: '2020',
            Links: 'https://ijhs.org.sa/index.php/journal/article/view/5249',
            JournalName: " International Journal of Health Sciences  ",
            DOI: '10.17605/OSF.IO/XG6H8',
            PMID: '33192230 , PMCID: PMC7644453  ',
            open: false
        },
        {
            heading: 'Clinical features, diagnosis, combined medical treatment, epidemiological characteristics and therapy of 26 conﬁrmed COVID-19 cases.',
            PublicationYear: '2020',
            Links: 'https://mid.journals.ekb.eg/article_102147.html',
            JournalName: " Microbes And Infectious Disease Journal ",
            DOI: '10.21608/MID.2020.28087.1008',
            PMID: 'N/A.',
            open: false
        },
        {
            heading: 'Diagnostic Value of High Resolution CT Imaging for COVID-19 in 24 Conﬁrmed Cases',
            PublicationYear: '2020',
            Links: 'https://www.banglajol.info/index.php/BJID/article/view/46795',
            JournalName: " Bangladesh Journal of Infectious Diseases  ",
            DOI: 'https://doi.org/10.3329/bjid.v7i0.46795',
            PMID: 'N/A.',
            open: false
        },
        {
            heading: 'Recent Diagnosis and Treatment Progress of Spinal Tuberculosis',
            PublicationYear: '2020',
            Links: 'https://www.fortunejournals.com/articles/recent-diagnosis-and-treatment-progress-of-spinal-tuberculosis.html',
            JournalName: "Fortune Journals",
            DOI: '10.26502/fjsrs009',
            PMID: 'N/A.',
            open: false
        },
        // Add more FAQ objects here
    ]);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    const toggleFAQ = (index) => {
        setFaqs(faqs.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open;
            } else {
                faq.open = false;
            }
            return faq;
        }));
    };
    
    return (
        <>
            <PosterOne img={aboutbanner} title={'Publications'} />
            {/* <Research_list /> */}
            <div className="container py-5">
                {/* <News data={newsdata} /> */}
                <div class="accordion">
                    <h1>Publications</h1>
                    <div class="accordion-item">
                        {faqs.map((faq, index) => (
                            <div key={index}>
                                <label class="accordion-item-title" onClick={() => toggleFAQ(index)}> <span class="icon">{faq.open ? '-' : '+'}</span>{faq.heading}</label>
                                {faq.open &&
                                    <>
                                        <div >
                                            <p className='accordion-item accordion-item-desc' style={{ display: "block" }}> Publication Year: <br /> {faq.PublicationYear}</p>
                                            <p className='accordion-item accordion-item-desc' style={{ display: "block" }}> Links:<br /> <li> <a target='blank' href={faq.Links}>{faq.Links}</a></li> </p>
                                            <p className='accordion-item accordion-item-desc' style={{ display: "block" }}> DOI: <br />{faq.DOI}</p>
                                            <p className='accordion-item accordion-item-desc' style={{ display: "block" }}> Journal Name:<br /> {faq.JournalName}</p>
                                            <p className='accordion-item accordion-item-desc' style={{ display: "block" }}> PMID:<br /> {faq.PMID}</p>
                                        </div>

                                    </>
                                }
                            </div>
                        ))}
                    </div>

                </div>

            </div>
        </>
    )
}

export default Research;


export const Research_list = () => {

    const research_data_list = [
        {
            img: research1,
            span: 'ONCOLOGY',
            h3: 'Catalysis Processes',
            p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
        },
        {
            img: research2,
            span: 'PATHOLOGY',
            h3: 'Organic Synthesis',
            p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
        },
        {
            img: research3,
            span: 'BIOSAFETY, INCUBATOR',
            h3: 'Hemoglobin Test',
            p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
        },
        {
            img: research3,
            span: 'HEMOGLOBIN',
            h3: 'Surface Cleaning',
            p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
        },
        {
            img: research1,
            span: 'ONCOLOGY',
            h3: 'Hemoglobin Test',
            p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
        },
        {
            img: research2,
            span: 'INCUBATOR',
            h3: 'Polymer Science',
            p: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam"
        },
    ]

    return <>
        <div className="container my-5">
            <div className="row">
                {
                    research_data_list.map((res, key) => {
                        return <div className="col-md-4" key={key}>
                            <div className="research_box">
                                <div className="img_box">
                                    <img src={res.img} alt="" />
                                </div>
                                <div className="content">
                                    <span>{res.span}</span>
                                    <h3>{res.h3}</h3>
                                    <p>{res.p}</p>
                                    <button className='btn-two'>Read More</button>
                                </div>
                            </div>
                        </div>
                    })
                }

            </div>
        </div>
    </>
}
import logo from './logo.svg';
import './App.css';
import './mycomponent/css/animtion.css';
import './mycomponent/css/config.css';
import './mycomponent/css/index.css';
import './mycomponent/css/header.css';
import './mycomponent/css/contact.css';
import './mycomponent/css/about.css';
import './mycomponent/css/gallery.css';
import './mycomponent/css/services.css';
import './mycomponent/css/research.css';
import './mycomponent/css/responsive.css';
import './mycomponent/css/blog.css';

import RouterConfrigration from './Navigation/routerConfigration';
import Scrooltop from './mycomponent/share/component.jsx/scrooltop';

function App() {
  return (
    <div className="App">
      <RouterConfrigration />
      <Scrooltop/>
    </div>
  );
}

export default App;
